/**
 * 系统 api 专用请求, 需要与其他如 oss 文件上传等区分开来
 */

import axios from 'axios'
import utils from '@/utils'
import encryption from './encryption'
import requestSetting from '@/setting/request'
import requestError from './error'
import qs from 'qs'

const instance = axios.create({
  baseURL: requestSetting.baseUrl,
  timeout: requestSetting.timeout,
})

instance.interceptors.request.use(
  (config) => {
    // console.log('-----request')
    // console.log(config)

    let params = {}
    if (!config.normalRequest) {
      params = utils.dict.ksort(utils.dict.filter(config.data))
    }
    // console.log('-----params')
    // console.log(params)
    if (requestSetting.apiconsole) {
      console.log('********【发送】*******【 ', config.url, '】')
      console.log(params)
    }
    const token = utils.storage.token.get()

    const tokenValue = token ? token.token : ''
    const timestamp = Date.now()
    const signature = requestSetting.debug
      ? 'SIGNATURE'
      : encryption.createSignature(
          tokenValue,
          utils.dict.createHttpQuery(params),
          timestamp,
        )

    const headers = {
      'Content-Type': requestSetting.debug
        ? 'application/x-www-form-urlencoded'
        : 'application/json',
      'X-TOKEN': tokenValue,
      'X-TIMESTAMP': timestamp,
      'X-SIGNATURE': signature,
    }
    config.headers = headers

    if (!config.normalRequest) {
      if (requestSetting.debug) {
        config.data = qs.stringify(config.data)
      } else {
        config.data = encryption.encrypt(JSON.stringify(config.data))
      }
    }

    // console.log(config)
    return config
  },
  (error) => {
    const message = error.message
    requestError.show(message)
    // requestError.log(error)
    return Promise.reject()
  },
)

instance.interceptors.response.use(
  (response) => {
    // console.log('-----response')

    const json = requestSetting.debug
      ? response.data
      : JSON.parse(encryption.decrypt(response.data))
    if (requestSetting.apiconsole) {
      console.log('********【接收】*******【 ', response.config.url, '】')
      console.log(json)
    }
    // console.log(json)

    if (typeof json !== 'object') {
      requestError.show('请求出现异常')
      return Promise.reject()
    }
    if (json.error === 0) {
      return Promise.resolve(json.data || null)
    } else if (json.error > 0) {
      requestError.show(json.message)
      return Promise.reject()
    }
  },
  (error) => {
    const message = error.message
    requestError.show(message)
    // requestError.log(error)
    return Promise.reject()
  },
)

export default instance
