export default {
  baseUrl: 'https://api-admin.uvod.tv', //线上
  // baseUrl: 'http://192.168.1.14:8811',
  // baseUrl: 'http://192.168.31.8:8014',

  // baseUrl: 'http://51.79.223.179:8014',
  // baseUrl: 'http://144.217.73.97:8411',
  // baseUrl: 'http://144.217.73.97:8010',
  openurl: 'https://www.uvod.tv', //打开播放页网址的域名
  timeout: 30 * 1000,
  channelId: '1',
  defaultLocale: 'zh-CN',
  requestWith: 'uvod-client',
  userAgent: 'uvod-client',

  apiconsole: true,

  debug: false,
}
