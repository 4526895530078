<template>
  <div>
    <ButtonGroup size="small">
      <Button type="primary" @click="handleOpenCreate">新增</Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        启用
      </Button>
      <Button
        type="error"
        :disabled="selectedIds.length < 1"
        @click="handleForbiddenMultiple"
      >
        禁用
      </Button>
    </ButtonGroup>

    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableVipMultiple"
      >
        恢复会员
      </Button>
      <Button
        type="error"
        :disabled="selectedIds.length < 1"
        @click="handleDisableVipMultiple"
      >
        撤销会员
      </Button>
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="id">
        <!-- <Badge v-if="row.status === 1" color="green" :text="row.id" />
        <Badge v-else-if="row.status === 0" color="yellow" :text="row.id" />
        <Badge v-else-if="row.status === -1" color="red" :text="row.id" /> -->
        {{ row.id }}
      </template>
      <template slot-scope="{ row }" slot="username">
        <!-- <Avatar shape="square" :src="row.avatar" v-if="row.avatar" /> -->
        <!-- <Avatar shape="square" icon="ios-person" v-else /> -->
        {{ row.username }}
      </template>

      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="正常" />
        <Badge v-else-if="row.status === 0" color="yellow" text="待审" />
        <Badge v-else-if="row.status === -1" color="red" text="禁用" />
      </template>

      <template slot-scope="{ row }" slot="vip">
        <Tag v-if="row.vip_expired_time > timestamp()" color="orange">
          付费
        </Tag>
        <Tag v-else color="blue">普通</Tag>

        <Tag v-if="row.vip_reversed > 0" color="red">撤</Tag>
      </template>
      <template slot-scope="{ row }" slot="vip_expired_time">
        <!-- <Tag v-if="row.vip_expired_time > timestamp()" color="orange">
          付费
        </Tag>
        <Tag v-else color="blue">普通</Tag>

        <Tag v-if="row.vip_reversed > 0" color="red">撤</Tag> -->
        {{
          row.vip_expired_time > timestamp()
            ? datetimeFormat(row.vip_expired_time)
            : ''
        }}
      </template>
      <template slot-scope="{ row }" slot="create_time">
        {{ row.create_time ? datetimeFormat(row.create_time) : '--' }}
      </template>
      <template slot-scope="{ row }" slot="last_signin_time">
        {{ row.last_signin_time ? datetimeFormat(row.last_signin_time) : '--' }}
      </template>
      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button type="primary" ghost @click="handleEdit(row.id)">
            编辑
          </Button>
        </ButtonGroup>
        <Poptip
          class="ivu-ml"
          trigger="hover"
          placement="bottom"
          transfer
          popper-class="popper-transfer"
        >
          <ButtonGroup size="small">
            <Button type="info" ghost>
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
          </ButtonGroup>

          <List size="small" slot="content">
            <ListItem>
              <a
                class="more-action-item"
                href="javascript:;"
                @click="handleOpenPassword(row.id)"
              >
                修改密码
              </a>
            </ListItem>
            <ListItem>
              <a class="more-action-item" href="javascript:;">人工充值</a>
            </ListItem>
            <ListItem>
              <a
                class="more-action-item"
                href="javascript:;"
                @click="handleOpenActiveList(row.id)"
              >
                活跃记录
              </a>
            </ListItem>
            <ListItem>
              <a class="more-action-item" href="javascript:;">充值记录</a>
            </ListItem>
          </List>
        </Poptip>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '编辑用户'
          : dataDrawer.type === 'new'
          ? '添加用户'
          : '用户信息'
      "
      width="560"
      :before-close="handleCloseEdit"
      transfer
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfo && dataInfo.email ? {} : dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32" v-if="dataDrawer.type !== 'new'">
          <Col span="10" class="ivu-text-center">
            <Avatar
              v-if="dataInfo.avatar"
              :size="64"
              shape="square"
              :src="dataInfo.avatar"
            />
            <Avatar v-else :size="64" shape="square" icon="ios-person" />
            <Upload
              v-if="dataDrawer.type === 'edit'"
              class="ivu-mt-8"
              action=""
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'git', 'png']"
              :before-upload="handleUploadAvatar"
            >
              <Button type="text">
                更换
                <Icon type="ios-create-outline" />
              </Button>
            </Upload>
          </Col>
          <Col span="14">
            <div>编号: {{ dataInfo.id }}</div>
            <div class="ivu-mt-8">登录次数: {{ dataInfo.signin_times }}</div>
            <div class="ivu-mt-8">
              注册时间:
              {{ datetimeFormat(dataInfo.create_time) }}
            </div>
            <div class="ivu-mt-8">
              最近登录时间:
              {{
                dataInfo.last_signin_time > timestamp
                  ? datetimeFormat(dataInfo.last_signin_time)
                  : '--'
              }}
            </div>
          </Col>
        </Row>
        <Divider v-if="dataDrawer.type !== 'new'" />
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="username" label="用户名">
              <Input
                size="small"
                v-model="dataInfo.username"
                placeholder="请输入用户名"
              />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="email" label="电子邮箱">
              <Input
                size="small"
                v-model="dataInfo.email"
                placeholder="请输入电子邮箱"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="nickname" label="昵称">
              <Input
                size="small"
                v-model="dataInfo.nickname"
                placeholder="请输入昵称"
                maxlength="20"
              />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="gender" label="性别">
              <RadioGroup size="small" v-model="dataInfo.gender">
                <Radio :label="0"><span>未知</span></Radio>
                <Radio :label="1"><span>男</span></Radio>
                <Radio :label="2"><span>女</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="password" label="密码">
              <Input
                size="small"
                v-model="dataInfo.password"
                placeholder="请输入密码(不填不修改)"
                maxlength="20"
              />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="vip_expired_time" label="会员到期时间">
              <DatePicker
                size="small"
                type="datetime"
                v-model="dataInfo.vip_expired_time"
                placeholder="请选择日期"
                v-width="'100%'"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="status" label="状态">
              <RadioGroup size="small" v-model="dataInfo.status">
                <Radio :label="1"><span>正常</span></Radio>
                <Radio :label="-1"><span>禁用</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>

        <FormItem prop="remark" label="备注">
          <Input
            size="small"
            v-model="dataInfo.remark"
            placeholder="请输入备注"
          />
        </FormItem>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>

    <Modal
      v-model="showPasswordModal"
      title="修改密码"
      width="540"
      lock-scroll
      :mask-closable="false"
    >
      <Form
        v-if="passwordInfo"
        ref="passwordInfoForm"
        :label-width="100"
        :model="passwordInfo"
        :rules="passwordInfoRules"
        style="margin-bottom: 50px"
      >
        <FormItem prop="password" label="新密码">
          <Input
            size="small"
            v-model="passwordInfo.password"
            placeholder="请输入新密码"
          />
        </FormItem>
      </Form>
      <div class="page-sys-user-drawer-footer" slot="footer">
        <Button
          type="primary"
          @click="handleSubmitPassword"
          :loading="submitting"
        >
          保存
        </Button>
      </div>
    </Modal>

    <Modal
      v-model="showActiveListModal"
      :mask-closable="false"
      title="活跃日志"
      width="768"
      footer-hide
    >
      <o-active-list ref="activeList" :uid="activeListUid" />
    </Modal>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'
import oActiveList from './active-list.vue'

export default {
  components: { oActiveList },
  data() {
    return {
      datetimeFormat,

      // pagesize: 20,
      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },

      passwordInfo: null, // 当前编辑数据信息
      passwordInfoRules: {},
      passwordInfoFormReady: true,
      passwordInfoString: null,

      showPasswordModal: false,

      activeListUid: 0,
      showActiveListModal: false,

      rechargeListUid: 0,
      showRechargeListModal: false,
    }
  },
  mixins: [dataListMixin],
  methods: {
    timestamp() {
      const t = parseInt(new Date().getTime() / 1000)
      return t
    },
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      const { keyword, status, vip, page } =
        this.$parent.$parent.$refs.filter.params
      if (page) {
        this.page = page
        this.$parent.$parent.$refs.filter.params.page = null
      }
      api.user
        .list({
          keyword,
          status,
          vip,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.user_list || []
          this.dataTotal = data.user_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.user
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.user || null
            this.dataInfo.password = ''
            this.dataInfo.vip_expired_time =
              this.dataInfo.vip_expired_time > 0
                ? new Date(this.dataInfo.vip_expired_time * 1000)
                : ''
            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.user
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量封号
    handleForbiddenMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.user
        .forbidden({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.user
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 批量撤销会员
    handleDisableVipMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.user
        .disableVip({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量恢复会员
    handleEnableVipMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.user
        .enableVip({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.user
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },
    // 添加
    handleOpenCreate() {
      this.dataInfo = {
        id: 0,
        avatar: '',
        username: '',
        email: '',
        nickname: '',
        gender: 0,
        password: '',
        vip_expired_time: '',
        remark: '',
        status: 1,
      }

      this.dataInfoString = this.createDataInfoString(this.dataInfo)
      this.dataDrawer.type = 'new'
      this.dataDrawer.show = true
      this.handleInitDataInfoForm()
    },
    handleSubmitEdit() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            username: this.dataInfo.username,
            email: this.dataInfo.email,
            nickname: this.dataInfo.nickname,
            avatar: this.dataInfo.avatar,
            gender: this.dataInfo.gender,
            password: this.dataInfo.password,
            vip_expired_time:
              this.dataInfo.vip_expired_time !== ''
                ? parseInt(this.dataInfo.vip_expired_time.getTime() / 1000)
                : 0,
            remark: this.dataInfo.remark,
            status: this.dataInfo.status,
          }
          // console.log(params)
          api.user
            .save(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },

    // 密码
    handleOpenPassword(id) {
      this.passwordInfo = {
        id,
        password: '',
      }

      this.passwordInfoString = this.createDataInfoString(this.passwordInfo)
      this.showPasswordModal = true
      this.handleInitPasswordInfoForm()
    },
    handleSubmitPassword() {
      this.$refs.passwordInfoForm.validate((valid) => {
        if (valid) {
          if (this.submitting) return
          this.submitting = true

          const params = {
            id: this.passwordInfo.id,
            password: this.passwordInfo.password,
          }
          api.user
            .changePassword(params)
            .then(() => {
              this.showPasswordModal = false
              this.$Message.success('修改成功')
            })
            .catch(() => {})
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitPasswordInfoForm() {
      this.passwordInfoFormReady = false
      this.$nextTick(() => {
        this.passwordInfoFormReady = true
      })
    },
    handleClosePassword() {
      // 判断内容是否修改，没修改则直接关闭
      if (this.passwordDrawer.type === 'edit') {
        if (
          this.passwordInfoString !==
          this.createDataInfoString(this.passwordInfo)
        ) {
          // eslint-disable-next-line
          return new Promise((resolve, reject) => {
            this.$Modal.confirm({
              title: '关闭确认',
              content: '您已经修改了信息，是否直接关闭？',
              onOk: () => {
                resolve()
              },
            })
          })
        }
      }
    },

    // 上传前拦截进行手动上传
    handleUploadAvatar(file) {
      // if (file.size > 40960000) {
      //   return false
      // }

      let params = new FormData()
      params.append('image', file)
      params.append('width', 72)
      params.append('height', 72)

      api.upload.userAvatar(params).then((data) => {
        this.dataInfo.avatar = data.url
      })

      return false
    },

    // 打开活跃modal
    handleOpenActiveList(uid) {
      this.activeListUid = uid
      this.showActiveListModal = true
      this.$refs.activeList.handleGetDataList()
    },
  },
}
</script>
<style lang="scss">
.popper-transfer {
  z-index: 999 !important;
}
.more-action-item {
  width: 100%;
  display: block;
}
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
