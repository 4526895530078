export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 50,
    maxWidth: 100,
    show: true,
  },
  {
    title: '平台',
    key: 'platform',
    slot: 'platform',
    show: true,
  },
  // {
  //   title: '站点',
  //   key: 'is_night',
  //   slot: 'is_night',
  //   show: true,
  // },
  {
    title: '名称',
    key: 'title',
    show: true,
  },
  {
    title: '添加时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
  },
  {
    title: '图片',
    key: 'source',
    slot: 'source',
    minWidth: 120,
    show: true,
  },
  {
    title: '是否新开页面',
    key: 'blank',
    slot: 'blank',
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    minWidth: 120,
    maxWidth: 160,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
