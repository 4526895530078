export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 80,
    show: true,
  },
  {
    title: '名称',
    key: 'name',
    slot: 'name',
    width: 170,
    show: true,
  },
  {
    title: '访问地址规则',
    key: 'prefix',
    slot: 'prefix',
    minWidth: 300,
    show: true,
  },
  {
    title: '视频后缀',
    key: 'video_suffix',
    slot: 'video_suffix',
    width: 170,
    show: true,
  },
  {
    title: '字幕后缀',
    key: 'subtitle_suffix',
    slot: 'subtitle_suffix',
    width: 170,
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    width: 120,
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
