<template>
  <div class="passport-login">
    <div class="container">
      <div class="top">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </div>
        <div class="description">UVOD.TV 管理后台</div>
      </div>

      <div class="main">
        <Login @on-submit="handleSubmit">
          <UserName
            name="username"
            maxlength="25"
            enter-to-submit
          ></UserName>
          <Password
            name="password"
            maxlength="25"
            enter-to-submit
          ></Password>
          <Captcha
            name="captcha"
            maxlength="6"
            :count-down="0"
            @on-get-captcha="handleGetCaptcha"
            enter-to-submit
          >
            <img slot="text" :src="captchaData" />
          </Captcha>
          <div class="auto-login">
            <Checkbox v-model="autoLogin" size="large">自动登录</Checkbox>
            <!-- <a href="">忘记密码</a> -->
          </div>
          <Submit></Submit>
        </Login>
        <!-- <div class="third-party">
          <span>其它登录方式</span>
          <img src="@/assets/svg/icon-social-wechat.svg" alt="wechat" />
          <img src="@/assets/svg/icon-social-qq.svg" alt="qq" />
          <img src="@/assets/svg/icon-social-weibo.svg" alt="weibo" />
          <router-link class="signup" :to="{ name: 'passport-signup' }">
            注册账户
          </router-link>
        </div> -->
      </div>
    </div>
    <o-copyright></o-copyright>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import oCopyright from '@/components/copyright'
import api from '@/api'

export default {
  name: 'passport-signin',
  components: { oCopyright },
  data() {
    return {
      autoLogin: true,

      captchaData: null,
      phraseId: null,
    }
  },
  methods: {
    ...mapActions('admin/passport', ['signin']),

    handleGetCaptcha() {
      api.captcha.index().then((data) => {
        if (data === undefined) return

        this.captchaData = 'data:image/jpeg;base64,' + data.captcha_data
        this.phraseId = data.phrase_id
      })
    },

    handleSubmit(valid, values) {
      if (valid) {
        const { username, password, captcha } = values
        this.signin({
          username,
          password,
          captcha,
          phrase_id: this.phraseId,
        }).then(() => {
          this.$router.replace(this.$route.query.redirect || '/')
        })
      }
    },
  },

  mounted() {
    this.handleGetCaptcha()
  },
}
</script>

<style lang="scss">
.passport-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .container {
    margin: 0 auto;
    width: 384px;
    flex: 1;
    padding: 32px 0;
    text-align: center;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    .top {
      padding: 32px 0;
      .logo {
        img {
          height: 75px;
        }
      }
      .description {
        font-size: 14px;
        color: #808695;
      }
    }
    .main {
      // padding: 16px;
      .auto-login {
        margin-bottom: 24px;
        text-align: left;
        a {
          float: right;
        }
      }
      .signup {
        float: right;
      }
    }
    .third-party {
      margin: 24px 0;
      text-align: left;
      span {
        font-size: 14px;
      }
      img {
        width: 24px;
        margin-left: 16px;
        cursor: pointer;
        vertical-align: middle;
        opacity: 0.7;
        transition: all 0.2s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .passport {
    background: url('../../../assets/svg/body.svg') no-repeat center;
    background-size: 100%;
    .container {
      padding: 32px 0 24px 0;
    }
  }
}
</style>
