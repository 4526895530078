import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

export default {
  path: '/video',
  name: 'video',
  redirect: {
    name: 'video-list',
  },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'fragment',
      name: 'video-fragment',
      meta: {
        ...meta,
        title: '分集管理',
      },
      component: () => import('@/views/video/list/fragment/index.vue'),
    },
    {
      path: 'list',
      name: 'video-list',
      meta: {
        ...meta,
        title: '片源管理',
      },
      component: () => import('@/views/video/list'),
    },
    {
      path: 'server',
      name: 'video-server',
      meta: {
        ...meta,
        title: '服务器管理',
      },
      component: () => import('@/views/video/server'),
    },
    {
      path: 'category',
      name: 'video-category',
      meta: {
        ...meta,
        title: '分类管理',
      },
      component: () => import('@/views/video/category'),
    },
    {
      path: 'filter',
      name: 'video-filter',
      meta: {
        ...meta,
        title: '筛选管理',
      },
      component: () => import('@/views/video/filter'),
    },

    {
      path: 'comment',
      name: 'video-comment',
      meta: {
        ...meta,
        title: '评论管理',
      },
      component: () => import('@/views/video/comment'),
    },
    {
      path: 'barrage',
      name: 'video-barrage',
      meta: {
        ...meta,
        title: '弹幕管理',
      },
      component: () => import('@/views/video/barrage'),
    },
    {
      path: 'report',
      name: 'video-report',
      meta: {
        ...meta,
        title: '报错管理',
      },
      component: () => import('@/views/video/report'),
    },
  ],
}
