export default {
  path: '/son',
  title: '子站管理',
  auth: 'son',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/son/wybanner',
      title: '午夜-海报轮播',
      auth: 'son-wybanner',
      custom: 'iconfont  icon-yewan1',
    },
    {
      path: '/son/wyguanggao',
      title: '午夜-广告管理',
      auth: 'son-wyguanggao',
      custom: 'iconfont  icon-yewan1',
    },
    {
      path: '/son/childbanner',
      title: '儿童-海报轮播',
      auth: 'son-childbanner',
      custom: 'iconfont  icon-yingerpiaozhichi',
    },
    {
      path: '/son/childguanggao',
      title: '儿童-广告管理',
      auth: 'son-childguanggao',
      custom: 'iconfont  icon-yingerpiaozhichi',
    },
    {
      path: '/son/childcollect',
      title: '儿童-资源合集',
      auth: 'son-childcollect',
      custom: 'iconfont  icon-yingerpiaozhichi',
    },
  ],
}
