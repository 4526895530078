export default {
  path: '/payment',
  title: '支付设置',
  auth: 'payment',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/payment/method',
      title: '支付方式',
      auth: 'payment-method',
    },
    {
      path: '/payment/gateway',
      title: '支付网关',
      auth: 'payment-gateway',
    },
    {
      path: '/payment/channel',
      title: '支付渠道',
      auth: 'payment-channel',
    },
  ],
}
