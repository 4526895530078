export default {
  path: '/vip',
  title: '会员套餐',
  auth: 'vip',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/vip-card/category',
      title: '点卡套餐',
      auth: 'vip-card-category',
    },
    {
      path: '/vip-card/management',
      title: '活动管理',
      auth: 'vip-card-management',
    },
    {
      path: '/vip-card/details',
      title: '折扣编辑',
      auth: 'vip-card-details',
    },
    {
      path: '/vip-card/list',
      title: '点卡管理',
      auth: 'vip-card-list',
    },
  ],
}
