import request from './request'

export default {
  list(data) {
    return request.post('/country/list', data)
  },
  info(data) {
    return request.post('/country/info', data)
  },
  save(data) {
    return request.post('/country/save', data)
  },
  enable(data) {
    return request.post('/country/enable', data)
  },
  disable(data) {
    return request.post('/country/disable', data)
  },
  delete(data) {
    return request.post('/country/delete', data)
  },

  countryList(data) {
    return request.post('/country/list', data)
  },
}
