// 菜单，侧边栏
import dashboard from './modules/dashboard'
import business from './modules/business'
import vip from './modules/vip'
import video from './modules/video'
import content from './modules/content'
import son from './modules/son'
import statistics from './modules/statistics'
import payment from './modules/payment'
import permission from './modules/permission'

// import system from './modules/system'
import account from './modules/account'

export default [
  dashboard,
  business,
  vip,
  video,
  content,
  son,
  statistics,
  payment,
  permission,
  // system,
  account,
]
