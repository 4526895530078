export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },

  {
    title: '编号',
    key: 'id',
    minWidth: 50,
    maxWidth: 100,
    show: true,
  },
  {
    title: '图标',
    key: 'icon',
    slot: 'icon',
    show: true,
  },
  {
    title: '名称',
    key: 'title',
    show: true,
  },
  {
    title: '合集资源',
    key: 'video_ids',
    slot: 'video_ids',
    show: true,
  },
  {
    title: '分类',
    key: 'category_id',
    slot: 'category_id',
    show: true,
  },
  {
    title: '添加时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
  },

  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    minWidth: 120,
    maxWidth: 160,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
