<template>
  <div>
    <Alert show-icon>
      <p>
        格式:
        <Tag color="blue">
          集数|服务器id|文件名|总时间|片头结束时间|片尾开始时间|是否使用自定义字幕|字幕支持语言|默认字幕语言
        </Tag>
        , 如:
        <Tag color="blue">100|1|xxxxxxxxxx|7200|90|90|1|cn,en|cn</Tag>
      </p>
      <p>
        集数、服务器id、文件名 为必填参数,
        总时间、片头结束时间、片尾开始时间、是否使用自定义字幕、字幕支持语言、默认字幕语言
        为选填参数
      </p>
      <p>
        一行一集, 同一集数如果在不同清晰度内都填写了数据，解析后会自动合并.
        在同一清晰度内填写了相同集数的数据, 后面数据会覆盖掉前面的
      </p>
      <p>注意: 每种清晰度需要单独解析</p>
    </Alert>

    <Tabs size="small" type="card">
      <TabPane label="标清360P">
        <Row :gutter="32">
          <Col span="20">
            <Input type="textarea" :rows="5" v-model="input_360p" />
          </Col>
          <Col span="4">
            <Button
              type="primary"
              @click="parseInput(1)"
              :disabled="input_360p.trim() === ''"
            >
              解析
            </Button>
          </Col>
        </Row>
      </TabPane>
      <TabPane label="高清480P">
        <Row :gutter="32">
          <Col span="20">
            <Input type="textarea" :rows="5" v-model="input_480p" />
          </Col>
          <Col span="4">
            <Button
              type="primary"
              @click="parseInput(2)"
              :disabled="input_480p.trim() === ''"
            >
              解析
            </Button>
          </Col>
        </Row>
      </TabPane>
      <TabPane label="超清720P">
        <Row :gutter="32">
          <Col span="20">
            <Input type="textarea" :rows="5" v-model="input_720p" />
          </Col>
          <Col span="4">
            <Button
              type="primary"
              @click="parseInput(3)"
              :disabled="input_720p.trim() === ''"
            >
              解析
            </Button>
          </Col>
        </Row>
      </TabPane>
      <TabPane label="蓝光1080P">
        <Row :gutter="32">
          <Col span="20">
            <Input type="textarea" :rows="5" v-model="input_1080p" />
          </Col>
          <Col span="4">
            <Button
              type="primary"
              @click="parseInput(4)"
              :disabled="input_1080p.trim() === ''"
            >
              解析
            </Button>
          </Col>
        </Row>
      </TabPane>
    </Tabs>

    <Divider size="small">华丽的分割线</Divider>

    <div>
      <!-- <ButtonGroup size="small">
        <Button
          @click="handleDeleteMultiple"
          type="error"
          :disabled="selectedIds.length < 1"
        >
          删除选中项
        </Button>
      </ButtonGroup> -->

      <ButtonGroup size="small" class="ivu-ml-8">
        <Button type="primary" @click="handleAddToHead">前面加一条</Button>
      </ButtonGroup>

      <div class="ivu-inline-block ivu-fr">
        <Button
          type="primary"
          :loading="submitting"
          @click="handleSubmitAll"
          :disabled="dataList.length < 1"
        >
          提交全部
        </Button>
      </div>
    </div>

    <Table :columns="tableColumns" :data="dataList" class="ivu-mt">
      <template slot-scope="{ index }" slot="symbol">
        <Input size="small" v-model="dataList[index].symbol" maxlenth="6" />
      </template>

      <template slot-scope="{ index }" slot="status">
        <i-switch
          size="small"
          v-model="dataList[index].status"
          :true-value="1"
          :false-value="0"
        />
      </template>

      <div
        class="video-fragment-qualities"
        slot-scope="{ index }"
        slot="qualities"
      >
        <Button
          :type="qualitySupport(index, 1) ? 'info' : 'default'"
          :icon="useSubtitle(index, 1) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 1)"
          @click="handleOpenSource(index, 1)"
        >
          标清
        </Button>
        <Button
          :type="qualitySupport(index, 2) ? 'primary' : 'default'"
          :icon="useSubtitle(index, 2) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 2)"
          @click="handleOpenSource(index, 2)"
        >
          高清
        </Button>
        <Button
          :type="qualitySupport(index, 3) ? 'success' : 'default'"
          :icon="useSubtitle(index, 3) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 3)"
          @click="handleOpenSource(index, 3)"
        >
          超清
        </Button>
        <Button
          :type="qualitySupport(index, 4) ? 'warning' : 'default'"
          :icon="useSubtitle(index, 4) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 4)"
          @click="handleOpenSource(index, 4)"
        >
          蓝光
        </Button>
      </div>

      <template slot-scope="{ index }" slot="action">
        <ButtonGroup size="small">
          <Button type="error" ghost @click="handleDelete(index)">
            删除
          </Button>
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt">
      <ButtonGroup size="small" class="ivu-ml-8">
        <Button type="primary" @click="handleAddToEnd">后面加一条</Button>
      </ButtonGroup>

      <div class="ivu-inline-block ivu-fr">
        <Button
          type="primary"
          :loading="submitting"
          @click="handleSubmitAll"
          :disabled="dataList.length < 1"
        >
          提交全部
        </Button>
      </div>
    </div>

    <Modal
      v-model="showSourceModal"
      :title="sourceModalTitle"
      width="768"
      footer-hide
      lock-scroll
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo"
        ref="dataInfoForm"
        :label-width="100"
        :model="dataInfo"
        :rules="dataInfoRules"
        style="margin-bottom: 50px"
      >
        <FormItem prop="video_server_id" label="服务器">
          <Select
            size="small"
            v-model="dataInfo.video_server_id"
            clearable
            transfer
            placeholder="请选择服务器"
          >
            <Option
              v-for="(item, index) in serverList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>

        <FormItem prop="file_name" label="文件目录名">
          <Input
            size="small"
            v-model="dataInfo.file_name"
            placeholder="文件目录名"
          />
        </FormItem>

        <Row :gutter="16">
          <Col span="8">
            <FormItem prop="duration" label="总时长(秒)">
              <Input
                size="small"
                v-model="dataInfo.duration"
                placeholder="总时长(秒)"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="front_duration" label="片头时长(秒)">
              <Input
                size="small"
                v-model="dataInfo.front_duration"
                placeholder="片头时长(秒)"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="tail_duration" label="片尾时长(秒)">
              <Input
                size="small"
                v-model="dataInfo.tail_duration"
                placeholder="片尾时长(秒)"
              />
            </FormItem>
          </Col>
        </Row>

        <FormItem prop="use_subtitle" label="自定义字幕">
          <i-switch
            size="small"
            v-model="dataInfo.use_subtitle"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>

        <FormItem prop="subtitles" label="字幕支持语言">
          <CheckboxGroup size="small" v-model="subtitleList">
            <Checkbox
              v-for="(item, index) in subtitleLanguageList"
              :label="item.symbol"
              :key="index"
              class="ivu-mr"
            >
              {{ item.label }}
            </Checkbox>
          </CheckboxGroup>
        </FormItem>

        <FormItem prop="default_subtitle" label="默认字幕语言">
          <RadioGroup size="small" v-model="dataInfo.default_subtitle">
            <template v-for="(item, index) in subtitleLanguageList">
              <Radio
                v-if="subtitleList.includes(item.symbol)"
                :label="item.symbol"
                :key="index"
                class="ivu-mr"
              >
                {{ item.label }}
              </Radio>
            </template>
          </RadioGroup>
        </FormItem>

        <FormItem prop="status" label="显示状态">
          <i-switch
            size="small"
            v-model="dataInfo.status"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button type="primary" @click="handleSubmitSource">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from '@/api'

export default {
  props: {
    videoId: {
      type: Number,
      required: true,
    },
    serverList: {
      type: Array,
      required: true,
    },
    subtitleLanguageList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          title: '集数',
          key: 'symbol',
          slot: 'symbol',
          show: true,
        },
        {
          title: '状态',
          key: 'status',
          slot: 'status',
          show: true,
        },
        {
          title: '清晰度(高亮=已设置, 星星=自定义字幕)',
          key: 'qualities',
          slot: 'qualities',
          minWidth: 240,
          maxWidth: 360,
          show: true,
        },
        {
          title: '操作',
          key: 'action',
          slot: 'action',
          show: true,
          width: 120,
          align: 'center',
          fixed: 'right',
        },
      ],

      dataInfo: {
        quality: 0,
        video_server_id: 0,
        file_name: '',
        use_subtitle: 0,
        subtitles: '',
        default_subtitle: '',
        duration: '',
        front_duration: '',
        tail_duration: '',
        status: 1,
      },
      dataInfoRules: {
        // video_server_id: [
        //   { required: true, message: '请选择服务器', trigger: 'blur' },
        // ],
        file_name: [{ required: true, message: '请填写文件名' }],
      },

      dataList: [],

      input_360p: '',
      input_480p: '',
      input_720p: '',
      input_1080p: '',

      showSourceModal: false,
      quality: 0,
      editFragmentIndex: null,

      submitting: false,

      subtitleList: [],
    }
  },
  computed: {
    // 动态设置列
    tableColumns() {
      const columns = [...this.columns]
      return columns.filter((item) => item.show)
    },
    // source modal title
    sourceModalTitle() {
      let title
      switch (this.quality) {
        case 1:
          title = '标清360P'
          break
        case 2:
          title = '高清480P'
          break
        case 3:
          title = '超清720P'
          break
        case 4:
          title = '蓝光1080P'
          break
        default:
          return '--'
      }

      return title
    },
  },
  methods: {
    // 鼠标悬停清晰度按钮的标题
    qualityTitle(index, quality) {
      const sourceList = this.dataList[index].source_list
      if (!sourceList) {
        return '--'
      }
      const i = sourceList.findIndex((item) => item.quality === quality)
      if (i < 0) {
        return '--'
      }

      const title =
        this.dataList[index].symbol +
        '|' +
        sourceList[i].video_server_id +
        '|' +
        sourceList[i].file_name +
        '|' +
        sourceList[i].duration +
        '|' +
        sourceList[i].front_duration +
        '|' +
        sourceList[i].tail_duration +
        '|' +
        sourceList[i].use_subtitle +
        '|' +
        sourceList[i].subtitles +
        '|' +
        sourceList[i].default_subtitle

      return title
    },
    // 是否支持指定的清晰度
    qualitySupport(index, quality) {
      const sourceList = this.dataList[index].source_list
      if (!sourceList) {
        return false
      }
      return sourceList.findIndex((item) => item.quality === quality) >= 0
    },
    // 指定是否使用自定义字幕
    useSubtitle(index, quality) {
      const sourceList = this.dataList[index].source_list
      if (!sourceList) {
        return false
      }
      const i = sourceList.findIndex((item) => item.quality === quality)
      if (i < 0) {
        return false
      }

      return sourceList[i].use_subtitle === 1
    },
    // 删除一条数据
    handleDelete(index) {
      this.dataList.splice(index, 1)
    },
    // 在头部添加一条
    handleAddToHead() {
      let symbol = ''
      if (this.dataList.length > 0) {
        if (/^\d+$/.test(this.dataList[0].symbol)) {
          const min = parseInt(this.dataList[0].symbol)
          if (min > 2) {
            symbol = String(min - 1)
          }
        }
      }

      const row = {
        symbol,
        status: 1,
        source_list: [],
      }
      this.dataList.unshift(row)
    },
    handleAddToEnd() {
      let symbol = ''
      if (this.dataList.length > 0) {
        const i = this.dataList.length - 1
        if (
          this.dataList[i].symbol !== '' &&
          /^\d+$/.test(this.dataList[i].symbol)
        ) {
          symbol = String(parseInt(this.dataList[i].symbol) + 1)
        }
      }
      const row = {
        symbol,
        status: 1,
        source_list: [],
      }
      this.dataList.push(row)
    },
    // 解析输入
    parseInput(quality) {
      let input
      switch (quality) {
        case 1:
          input = this.input_360p
          break
        case 2:
          input = this.input_480p
          break
        case 3:
          input = this.input_720p
          break
        case 4:
          input = this.input_1080p
          break
        default:
          this.$Message.error('未知清晰度')
          return false
      }

      const fragmentList = input.trim().split(/[\n]+/)

      try {
        fragmentList.forEach((text) => {
          if (text === '') {
            return false
          }
          const parsed = text.split('|')
          // console.log(parsed)
          if (parsed.length < 3) {
            throw '前3个必填参数不可少'
            // return false
          }

          const symbol = parsed[0].trim()

          const sourceData = {
            quality,
            video_server_id: parseInt(parsed[1]),
            file_name: parsed[2].trim(),
            duration:
              parsed.length > 3
                ? parseInt(parsed[3])
                  ? parseInt(parsed[3])
                  : 0
                : 0,
            front_duration:
              parsed.length > 4
                ? parseInt(parsed[4])
                  ? parseInt(parsed[4])
                  : 0
                : 0,
            tail_duration:
              parsed.length > 5
                ? parseInt(parsed[5])
                  ? parseInt(parsed[5])
                  : 0
                : 0,
            use_subtitle:
              parsed.length > 6
                ? parseInt(parsed[6])
                  ? parseInt(parsed[6])
                  : 0
                : 0,
            subtitles: parsed.length > 7 ? parsed[7].trim() : '',
            default_subtitle: parsed.length > 8 ? parsed[8].trim() : '',
            status: 1,
          }

          // 找对应集数的数据
          const fragmentIndex = this.dataList.findIndex(
            (item) => item.symbol === symbol,
          )

          if (fragmentIndex >= 0) {
            // 找对应清晰度的数据
            const sourceIndex = this.dataList[
              fragmentIndex
            ].source_list.findIndex((item) => item.quality === quality)

            if (sourceIndex >= 0) {
              this.dataList[fragmentIndex].source_list[sourceIndex] = sourceData
            } else {
              this.dataList[fragmentIndex].source_list.push(sourceData)
            }
          } else {
            const fragmentData = {
              symbol,
              status: 1,
              source_list: [sourceData],
            }
            this.dataList.push(fragmentData)
          }
        })
      } catch (e) {
        this.$Message.error(e)
        return false
      }

      switch (quality) {
        case 1:
          this.input_360p = ''
          break
        case 2:
          this.input_480p = ''
          break
        case 3:
          this.input_720p = ''
          break
        case 4:
          this.input_1080p = ''
          break
        default:
          return false
      }

      return true
    },
    // 提交全部
    handleSubmitAll() {
      if (this.submitting) return
      this.submitting = true
      console.log(this.dataList)
      api.videoFragment
        .saveMultiple({
          video_id: this.videoId,
          video_fragment_list: this.dataList,
        })
        .then(() => {
          this.$Message.success('操作成功')
          this.dataList = []
          this.dataInfo = {
            quality: 0,
            video_server_id: 0,
            file_name: '',
            use_subtitle: 0,
            subtitles: '',
            default_subtitle: '',
            status: 1,
          }
          this.$emit('completed', true)
        })
        .finally(() => {
          this.submitting = false
        })
    },
    // 打开资源设置
    handleOpenSource(fragmentIndex, quality) {
      const sourceIndex = this.dataList[fragmentIndex].source_list.findIndex(
        (item) => item.quality === quality,
      )

      if (sourceIndex >= 0) {
        this.dataInfo = this.dataList[fragmentIndex].source_list[sourceIndex]
        this.subtitleList = this.dataInfo.subtitles.split(',')
      } else {
        this.dataInfo = {
          quality,
          video_server_id: 0,
          file_name: '',
          use_subtitle: 0,
          subtitles: '',
          default_subtitle: '',
          status: 1,
        }
        this.subtitleList = []
      }

      this.showSourceModal = true
      this.quality = quality
      this.editFragmentIndex = fragmentIndex
    },
    // 确定编辑的单条资源
    handleSubmitSource() {
      const sourceIndex = this.dataList[
        this.editFragmentIndex
      ].source_list.findIndex((item) => {
        item.quality === this.quality
      })

      let sourceData = this.dataInfo
      sourceData.subtitles = this.subtitleList.join(',')
      // console.log(sourceData)
      if (sourceIndex >= 0) {
        // eslint-disable-next-line
        this.dataList[this.editFragmentIndex].source_list[sourceIndex] =
          // eslint-disable-next-line
          sourceData
      } else {
        this.dataList[this.editFragmentIndex].source_list.push(sourceData)
      }
      this.showSourceModal = false
      this.quality = 0
      this.editFragmentIndex = null
    },
  },
}
</script>
