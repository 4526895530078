<template>
  <Row :gutter="24">
    <Col span="24">
      <h4>{{ model[visitType] }}</h4>
      <div ref="visitChart" v-height="240"></div>
    </Col>
  </Row>
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    statisticsList: {
      type: Array,
      default() {
        return []
      },
    },
    visitType: {
      type: String,
      default() {
        return 'active'
      },
    },
  },
  data() {
    return {
      model: {
        active: '访问量',
        payMoney: '充值金额',
        payNum: '充值订单',
        increment: '新用户',
      },
      dataX: [],
      active: [], // 活跃
      payMoney: [], // 支付金额
      payNum: [], // 订单数量
      increment: [], // 新增用户
      load: false,
    }
  },
  methods: {
    init() {
      this.dateX = []
      this.payMoney = []
      this.payNum = []
      this.increment = []
      // console.log(this.statisticsList)
      this.statisticsList.forEach((e) => {
        // 日期变量
        if (e.target_date) {
          const dateSplit = e.target_date.split('-')
          if (dateSplit && dateSplit?.length && dateSplit.length === 3) {
            this.dateX.unshift(`${dateSplit[1]}-${dateSplit[2]}`)
          }
        }
        // 数值变量
        this.active.unshift(e.user_active_1d)
        this.payMoney.unshift(e.payment_order_amount_increment_1d)
        this.increment.unshift(e.user_increment_1d)
        this.payNum.unshift(e.payment_order_count_increment_1d)
      })
      if (!this.load) this.handleSetVisitChart()
    },
    handleSetVisitChart() {
      let dataList = []
      switch (this.visitType) {
        case 'active':
          dataList = this.active
          break
        case 'payMoney':
          dataList = this.payMoney
          break
        case 'payNum':
          dataList = this.payNum
          break
        case 'increment':
          dataList = this.increment
          break

        default:
          dataList = []
          break
      }

      this.visitChart = echarts.init(this.$refs.visitChart)
      this.visitChart.setOption({
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#D7DDE4',
            },
          },
          axisTick: {
            alignWithLabel: true,
            lineStyle: {
              color: '#D7DDE4',
            },
          },
          axisLabel: {
            textStyle: {
              color: '#7F8B9C',
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#F5F7F9',
            },
          },
          data: this.dateX,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#7F8B9C',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F5F7F9',
            },
          },
          type: 'value',
        },
        series: [
          {
            data: dataList,
            type: 'bar',
            tooltip: true,
            smooth: true,
            symbol: 'none',
            itemStyle: {
              normal: {
                barBorderRadius: [3, 3, 0, 0],
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#69cdff',
                  },
                  {
                    offset: 0.5,
                    color: '#3eb3f7',
                  },
                  {
                    offset: 1,
                    color: '#1495eb',
                  },
                ]),
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    //数值样式
                    color: '#515a6e',
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],
        color: ['#1495EB', '#00CC66', '#F9D249', '#ff9900', '#9860DF'],
        grid: {
          left: 16,
          right: 25,
          bottom: 5,
          top: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
        },
      })
      this.load = true
    },
    handleResize() {
      this.visitChart.resize()
    },
  },
  mounted() {
    // this.handleSetVisitChart()
  },
  beforeDestroy() {
    if (this.visitChart) {
      this.visitChart.dispose()
      this.visitChart = null
    }
  },
}
</script>

<style lang="scss">
.dashboard-console-visit {
  ul {
    li {
      list-style-type: none;
      margin-top: 12px;
    }
  }
}
</style>
