<template>
  <GlobalFooter class="copyright" :links="links" :copyright="copyright" />
</template>

<script>
export default {
  name: 'oCopyright',
  data() {
    return {
      links: [
        {
          title: '官网',
          key: '官网',
          href: 'https://www.uvod.com',
          blankTarget: true,
        },
      ],
      copyright: 'Copyright © 2021 UVOD.TV',
    }
  },
}
</script>

<style lang="scss">
.copyright {
  flex: 0 0 auto;

  &-hidden {
    display: none;
  }
}
</style>
