<template>
  <Form
    ref="form"
    :model="params"
    col="6"
    :rules="rules"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <Row type="flex">
      <Col v-bind="grid">
        <FormItem label="关键词" prop="keyword" label-for="keyword">
          <Input
            size="small"
            v-model="params.keyword"
            clearable
            placeholder="名称|编号"
          />
        </FormItem>
      </Col>

      <Col v-bind="grid">
        <FormItem label="平台" prop="platform">
          <Select
            size="small"
            v-model="params.platform"
            clearable
            placeholder="请选择平台"
          >
            <Option value="1">PC</Option>
            <Option value="2">移动</Option>
          </Select>
        </FormItem>
      </Col>
      <!-- <Col v-bind="grid">
        <FormItem label="站点" prop="is_night">
          <Select
            size="small"
            v-model="params.is_night"
            clearable
            placeholder="请选择站点"
          >
            <Option value="0">默认</Option>
            <Option value="1">午夜版</Option>
          </Select>
        </FormItem>
      </Col> -->
      <Col v-bind="grid">
        <FormItem label="状态" prop="status">
          <Select
            size="small"
            v-model="params.status"
            clearable
            placeholder="请选择状态"
          >
            <Option value="1">正常</Option>
            <Option value="0">禁用</Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="grid" class="ivu-text-right">
        <FormItem>
          <ButtonGroup size="small">
            <Button type="primary" @click="handleSubmit">查询</Button>
            <Button @click="handleReset">重置</Button>
          </ButtonGroup>
          <!-- <a v-font="14" class="ivu-ml-8" @click="collapse = !collapse">
            <template v-if="!collapse">
              展开
              <Icon type="ios-arrow-down" />
            </template>
            <template v-else>
              收起
              <Icon type="ios-arrow-up" />
            </template>
          </a> -->
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import dataFilterMixin from '@/mixins/data-filter'

export default {
  data() {
    return {
      grid: {
        xl: 4,
        lg: 4,
        md: 6,
        sm: 22,
        xs: 22,
      },
      params: {
        keyword: '',
        category_id: '',
        type: '',
        status: '',
        date: [],
      },
      rules: {},
    }
  },
  mixins: [dataFilterMixin],
  computed: {
    ...mapState('admin/layout', ['isMobile']),
    labelWidth() {
      return this.isMobile ? undefined : 88
    },
    labelPosition() {
      return this.isMobile ? 'top' : 'right'
    },
  },
  methods: {
    handleSubmit() {
      // console.log(this.params)
      this.params.page = 1
      this.$emit('on-submit', this.params)
    },
    handleReset() {
      this.$refs.form.resetFields()
      // this.$emit('on-reset')
    },
  },
}
</script>
