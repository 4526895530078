export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 50,
    maxWidth: 100,
    show: true,
  },
  {
    title: '平台',
    key: 'platform',
    slot: 'platform',
    show: true,
  },
  {
    title: '名称',
    key: 'title',
    show: true,
  },
  {
    title: '广告位',
    key: 'category_id',
    slot: 'category',
    show: true,
  },
  {
    title: '类型',
    key: 'type',
    slot: 'type',
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 160,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
