<template>
  <div>
    <ButtonGroup size="small">
      <Button type="primary" @click="handleOpenCreate">新增</Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        启用
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        禁用
      </Button>
      <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button>
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="platform">
        <Tag color="blue">{{ platformLabel(row.platform) }}</Tag>
      </template>
      <template slot-scope="{ row }" slot="create_time">
        {{ datetimeFormat(row.create_time) }}
      </template>
      <div class="banner-source" slot-scope="{ row }" slot="source">
        <div>
          <img
            class="banner-source-image"
            :src="row.source"
            width="120"
            title="点击预览"
          />
        </div>
        <div>
          <span>
            尺寸: {{ platformSourceWidth(row.platform) }} *
            {{ platformSourceHeight(row.platform) }} px
          </span>
          <a :href="row.url" target="_blank">点击跳转</a>
        </div>
      </div>
      <template slot-scope="{ row }" slot="blank">
        <Tag v-if="row.blank === 1" color="blue">是</Tag>
        <Tag v-else-if="row.blank === 0" color="warning">否</Tag>
      </template>
      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="正常" />
        <Badge v-else-if="row.status === 0" color="yellow" text="禁用" />
      </template>
      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button type="primary" ghost @click="handleEdit(row.id)">
            编辑
          </Button>
          <Button type="error" ghost @click="handleDelete(row.id)">
            删除
          </Button>
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '儿童站 - 编辑海报'
          : dataDrawer.type === 'new'
          ? '儿童站 - 添加海报'
          : '儿童站 - 海报信息'
      "
      width="540"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="platform" label="平台">
              <Select
                size="small"
                v-model="dataInfo.platform"
                placeholder="请选择"
              >
                <Option
                  v-for="(item, index) in platforms"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.label }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="title" label="标题">
              <Input
                size="small"
                v-model="dataInfo.title"
                placeholder="请输入标题"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="subtitle" label="副标题">
              <Input
                size="small"
                v-model="dataInfo.subtitle"
                placeholder="请输入副标题"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <Alert type="warning" v-if="dataInfo.platform">
              {{ selectPlatformTip(dataInfo.platform) }}
            </Alert>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="source" label="图片地址">
              <Input
                size="small"
                v-model="dataInfo.source"
                placeholder="请输入图片地址"
              >
                <Upload
                  slot="append"
                  action=""
                  :show-upload-list="false"
                  :before-upload="handleUploadSource"
                >
                  <Button
                    size="small"
                    type="primary"
                    style="background-color: #2d8cf0; color: #fff"
                  >
                    上传
                  </Button>
                </Upload>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="url" label="跳转地址">
              <Input
                size="small"
                v-model="dataInfo.url"
                placeholder="请输入跳转地址"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="blank" label="是否新开页面">
              <RadioGroup size="small" v-model="dataInfo.blank">
                <Radio :label="1"><span>是</span></Radio>
                <Radio :label="0"><span>否</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="sort" label="排序">
              <Input
                size="small"
                v-model="dataInfo.sort"
                placeholder="-99 ~ 99"
                maxlength="3"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="status" label="状态">
              <RadioGroup size="small" v-model="dataInfo.status">
                <Radio :label="1"><span>正常</span></Radio>
                <Radio :label="0"><span>禁用</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <div class="page-sys-footer-copy">
          <Button
            type="primary"
            @click="copyJSON('bannerCopy')"
            :loading="submitting"
          >
            拓
          </Button>
          <Button @click="paste('bannerCopy')" :loading="submitting">
            印
          </Button>
        </div>
        <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'

import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'

export default {
  data() {
    return {
      datetimeFormat,

      platforms: [
        {
          value: 1,
          label: 'PC',
          width: 1920,
          height: 670,
        },
        {
          value: 2,
          label: '移动',
          width: 343,
          height: 170,
        },
      ],
      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },
    }
  },
  mixins: [dataListMixin],
  methods: {
    copyJSON(name) {
      const text = { name: name, json: JSON.stringify(this.dataInfo) }
      this.$Copy({
        text: JSON.stringify(text),
      })
    },
    paste(name) {
      navigator.clipboard
        .readText()
        .then((textjson) => {
          try {
            if (!textjson) return

            const text = JSON.parse(textjson)
            if (text.name != name) {
              throw new Error('该数据不是当前页面数据')
            }
            if (text && text.name && text.json && text.name == name) {
              let jsonObj = JSON.parse(text.json)
              jsonObj.id = 0
              delete jsonObj.create_time
              delete jsonObj.is_children
              delete jsonObj.is_night
              this.dataInfo = jsonObj
              this.$Message.success('粘贴成功')
            }
          } catch {
            this.$Message.error(`粘贴错误，数据有误，请检查`)
          }
        })
        .catch((err) => {
          console.error('Failed to read clipboard contents: ', err)
        })
      name
    },
    platformLabel(platform) {
      for (const i in this.platforms) {
        if (this.platforms[i].value === platform) {
          return this.platforms[i].label
        }
      }
      return '--'
    },
    platformSourceWidth(platform) {
      for (const i in this.platforms) {
        if (this.platforms[i].value === platform) {
          return this.platforms[i].width
        }
      }
      return 0
    },
    platformSourceHeight(platform) {
      for (const i in this.platforms) {
        if (this.platforms[i].value === platform) {
          return this.platforms[i].height
        }
      }
      return 0
    },
    selectPlatformTip(platform) {
      if (!platform) return ''

      let text = ''
      for (const i in this.platforms) {
        if (this.platforms[i].value === platform) {
          text = `图片尺寸: ${this.platforms[i].width} * ${this.platforms[i].height} px`
          break
        }
      }
      return text
    },
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      const {
        page,
        platform,
        is_children = '1',
        status,
      } = this.$parent.$parent.$refs.filter.params
      if (page) this.page = page
      api.banner
        .list({
          // keyword,
          // status,
          platform,
          is_children,
          status,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.banner_list || []
          this.dataTotal = data.banner_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.banner
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.banner || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.banner
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量禁用
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.banner
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.banner
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.banner
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },
    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },
    // 添加
    handleOpenCreate() {
      this.dataInfo = {
        id: 0,
        platform: 0,
        title: '',
        subtitle: '',
        source: '',
        url: '',
        blank: 0,
        is_children: 1,
        sort: 50,
        status: 1,
      }

      this.dataInfoString = this.createDataInfoString(this.dataInfo)
      this.dataDrawer.type = 'new'
      this.dataDrawer.show = true
      this.handleInitDataInfoForm()
    },
    handleSubmitEdit() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            platform: this.dataInfo.platform,
            title: this.dataInfo.title,
            subtitle: this.dataInfo.subtitle,
            source: this.dataInfo.source,
            url: this.dataInfo.url,
            blank: this.dataInfo.blank,
            is_children: this.dataInfo.is_children,
            sort: this.dataInfo.sort,
            status: this.dataInfo.status,
          }
          // console.log(params)
          api.banner
            .save(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },

    handleUploadSource(file) {
      let params = new FormData()
      params.append('image', file)
      // params.append('width', 72)
      // params.append('height', 72)

      api.upload.bannerPoster(params).then((data) => {
        this.dataInfo.source = data.url
      })

      return false
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
.banner-source {
  margin: 5px;
  display: flex;

  &-image {
    margin-right: 5px;
    vertical-align: middle;
    cursor: pointer;
  }
  span,
  a {
    display: block;
  }
}
</style>
