<template>
  <div>
    <ButtonGroup size="small">
      <Button :disabled="addNew" type="primary" @click="handleAdd">
        新增
      </Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button type="primary" @click="handleOpenCreateMultiple">
        批量新增
      </Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        显示
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        隐藏
      </Button>
      <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button>
    </ButtonGroup>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row, index }" slot="symbol">
        <Input
          v-if="editIndex === index"
          size="small"
          v-model="editInfo.symbol"
          maxlenth="6"
        />
        <template v-else>
          {{ row.symbol }}
        </template>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <i-switch
          v-if="editIndex === index"
          size="small"
          v-model="editInfo.status"
          :true-value="1"
          :false-value="0"
        />
        <template v-else>
          <Badge v-if="row.status === 1" color="green" text="显示" />
          <Badge v-else-if="row.status === 0" color="yellow" text="隐藏" />
        </template>
      </template>

      <template slot-scope="{ row }" slot="create_time">
        {{ datetimeFormat(row.create_time) }}
      </template>

      <div
        class="video-fragment-qualities"
        slot-scope="{ row, index }"
        slot="qualities"
      >
        <Button
          :type="qualitySupport(index, 1) ? 'info' : 'default'"
          :icon="useSubtitle(index, 1) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 1)"
          @click="handleOpenSource(row.id, 1)"
          :disabled="row.id < 1"
        >
          标清
        </Button>
        <Button
          :type="qualitySupport(index, 2) ? 'primary' : 'default'"
          :icon="useSubtitle(index, 2) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 2)"
          @click="handleOpenSource(row.id, 2)"
          :disabled="row.id < 1"
        >
          高清
        </Button>
        <Button
          :type="qualitySupport(index, 3) ? 'success' : 'default'"
          :icon="useSubtitle(index, 3) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 3)"
          @click="handleOpenSource(row.id, 3)"
          :disabled="row.id < 1"
        >
          超清
        </Button>
        <Button
          :type="qualitySupport(index, 4) ? 'warning' : 'default'"
          :icon="useSubtitle(index, 4) ? 'ios-star' : ''"
          size="small"
          :title="qualityTitle(index, 4)"
          @click="handleOpenSource(row.id, 4)"
          :disabled="row.id < 1"
        >
          蓝光
        </Button>
      </div>

      <template slot-scope="{ row, index }" slot="action">
        <template v-if="editIndex === index">
          <ButtonGroup size="small">
            <Button type="success" ghost @click="handleSave(index)">
              <template v-if="!addNew">保存</template>
              <template v-else>添加</template>
            </Button>
            <Button v-if="!addNew" type="primary" ghost @click="handleCancel">
              取消
            </Button>
            <Button
              v-else
              @click="handleCancelAdd(index)"
              type="error"
              ghost
              size="small"
            >
              删除
            </Button>
          </ButtonGroup>
        </template>
        <template v-else>
          <ButtonGroup size="small">
            <Button type="primary" ghost @click="handleEdit(row, index)">
              编辑
            </Button>
            <Button type="error" ghost @click="handleDelete(row.id)">
              删除
            </Button>
          </ButtonGroup>
        </template>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Modal
      v-model="showSourceModal"
      :title="sourceModalTitle"
      width="768"
      class="video_list_fragment_detail"
      footer-hide
      lock-scroll
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo"
        ref="dataInfoForm"
        :label-width="120"
        :model="dataInfo"
        :rules="dataInfoRules"
        style="margin-bottom: 50px"
      >
        <FormItem prop="video_server_id" label="服务器">
          <Select
            size="small"
            v-model="dataInfo.video_server_id"
            clearable
            transfer
            placeholder="请选择服务器"
          >
            <Option
              v-for="(item, index) in serverList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>

        <FormItem prop="file_name" label="文件目录名">
          <Input
            size="small"
            v-model="dataInfo.file_name"
            placeholder="文件目录名"
          />
        </FormItem>

        <Row :gutter="16">
          <Col span="8">
            <FormItem prop="duration" label="总时间(秒)">
              <Input
                size="small"
                v-model="dataInfo.duration"
                placeholder="总时间"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="front_duration" label="片头结束时间(秒)">
              <Input
                size="small"
                v-model="dataInfo.front_duration"
                placeholder="片头结束时间"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="tail_duration" label="片尾开始时间(秒)">
              <Input
                size="small"
                v-model="dataInfo.tail_duration"
                placeholder="片尾开始时间"
              />
            </FormItem>
          </Col>
        </Row>

        <Row :gutter="16">
          <Col span="8">
            <FormItem>
              <span style="">*设置区间值=></span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="front_duration" label="片头开始时间(秒)">
              <Input
                size="small"
                v-model="dataInfo.front_start_duration"
                placeholder="需小于上方时间"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="tail_duration" label="片尾结束时间(秒)">
              <Input
                size="small"
                v-model="dataInfo.tail_end_duration"
                placeholder="需大于上方时间"
              />
            </FormItem>
          </Col>
        </Row>

        <FormItem prop="use_subtitle" label="自定义字幕">
          <i-switch
            size="small"
            v-model="dataInfo.use_subtitle"
            :true-value="1"
            :false-value="0"
          />
        </FormItem>

        <FormItem prop="subtitles" label="字幕支持语言">
          <CheckboxGroup size="small" v-model="subtitleList">
            <Checkbox
              v-for="(item, index) in subtitleLanguageList"
              :label="item.symbol"
              :key="index"
              class="ivu-mr"
            >
              {{ item.label }}
            </Checkbox>
          </CheckboxGroup>
        </FormItem>

        <FormItem prop="default_subtitle" label="默认字幕语言">
          <RadioGroup size="small" v-model="dataInfo.default_subtitle">
            <template v-for="(item, index) in subtitleLanguageList">
              <Radio
                v-if="subtitleList.includes(item.symbol)"
                :label="item.symbol"
                :key="index"
                class="ivu-mr"
              >
                {{ item.label }}
              </Radio>
            </template>
          </RadioGroup>
        </FormItem>

        <Row :gutter="16">
          <Col span="8">
            <FormItem prop="status" label="显示状态">
              <i-switch
                size="small"
                v-model="dataInfo.status"
                :true-value="1"
                :false-value="0"
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitSource"
          :loading="submitting"
        >
          保存
        </Button>
      </div>
    </Modal>

    <Modal
      :styles="createMultipleModalStyles"
      v-model="showCreateMultipleModal"
      title="批量添加"
      width="1024"
      :mask-closable="false"
      footer-hide
    >
      <o-create-multiple
        :video-id="videoId"
        :server-list="serverList"
        :subtitle-language-list="subtitleLanguageList"
        @completed="handleCloseCreateMultiple"
      />
    </Modal>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'
import oCreateMultiple from './create-multiple'
// import requestSetting from '@/setting/request'
export default {
  props: {
    videoId: {
      type: Number,
      required: true,
    },
  },
  components: { oCreateMultiple },
  data() {
    return {
      datetimeFormat,
      editIndex: -1,
      addNew: false,
      editInfo: {
        symbol: '',
        status: 1,
        create_time: 0,
      },
      serverList: [],

      columns: tableColumns,

      dataInfoRules: {
        // video_server_id: [
        //   { required: true, message: '请选择服务器', trigger: 'blur' },
        // ],
        file_name: [{ required: true, message: '请填写文件名' }],
      },

      showSourceModal: false,
      quality: 0,

      showCreateMultipleModal: false,
      createMultipleModalStyles: {
        height: 'calc(100%)',
        overflow: 'auto',
        margin: 'auto',
        position: 'static',
      },
      // 字幕语言列表
      subtitleLanguageList: [
        {
          symbol: 'zh-cn',
          label: '中文-简体',
        },
        {
          symbol: 'zh-hk',
          label: '中文-繁体',
        },
        {
          symbol: 'es',
          label: '西班牙语',
        },
        {
          symbol: 'ja',
          label: '日语',
        },
        {
          symbol: 'ko',
          label: '韩语',
        },
        {
          symbol: 'id',
          label: '印尼语',
        },
      ],

      subtitleList: [],
    }
  },
  mixins: [dataListMixin],
  computed: {
    // source modal title
    sourceModalTitle() {
      let title
      switch (this.quality) {
        case 1:
          title = '标清360P'
          break
        case 2:
          title = '高清480P'
          break
        case 3:
          title = '超清720P'
          break
        case 4:
          title = '蓝光1080P'
          break
        default:
          return '--'
      }

      return title
    },
    haveOther() {
      return this.abnormalListData.filter((item) => item == 5)
    },
  },
  methods: {
    // 鼠标悬停清晰度按钮的标题
    qualityTitle(index, quality) {
      const sourceList = this.dataList[index].source_list
      if (!sourceList) {
        return '--'
      }
      const i = sourceList.findIndex((item) => item.quality === quality)
      if (i < 0) {
        return '--'
      }

      const title =
        this.dataList[index].symbol +
        '|' +
        sourceList[i].video_server_id +
        '|' +
        sourceList[i].file_name +
        '|' +
        sourceList[i].duration +
        '|' +
        sourceList[i].front_duration +
        '|' +
        sourceList[i].tail_duration +
        '|' +
        sourceList[i].use_subtitle +
        '|' +
        sourceList[i].subtitles +
        '|' +
        sourceList[i].default_subtitle

      return title
    },
    // 是否支持指定的清晰度
    qualitySupport(index, quality) {
      const sourceList = this.dataList[index].source_list
      if (!sourceList) {
        return false
      }
      return sourceList.findIndex((item) => item.quality === quality) >= 0
    },
    // 指定是否使用自定义字幕
    useSubtitle(index, quality) {
      const sourceList = this.dataList[index].source_list
      if (!sourceList) {
        return false
      }
      const i = sourceList.findIndex((item) => item.quality === quality)
      if (i < 0) {
        return false
      }
      return sourceList[i].use_subtitle === 1
    },
    timestamp() {
      const t = parseInt(new Date().getTime() / 1000)
      return t
    },

    // 取服务器列表
    handleGetServerList() {
      api.videoSource
        .server({
          // status: 1,
        })
        .then((data) => {
          if (!data) return
          this.serverList = data.video_server_list || []
        })
        .catch(() => {})
    },

    // 取数据列表
    handleGetDataList() {
      if (this.videoId < 1) {
        return false
      }

      if (this.loading) return
      this.loading = true

      this.handleGetServerList()

      // 下面的 params 是获取的表单查询参数
      const { symbol, status } = this.$parent.$parent.$refs.filter.params
      api.videoFragment
        .list({
          video_id: this.videoId,
          symbol,
          status,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.video_fragment_list || []
          this.dataTotal = data.video_fragment_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.videoFragment
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.video_fragment || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量显示
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.videoFragment
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量隐藏
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.videoFragment
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.videoFragment
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.videoFragment
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },

    // 新增
    handleAdd() {
      this.addNew = true
      const row = {
        id: '',
        symbol: '',
        status: 1,
        create_time: 0,
        qualities: [],
      }
      this.dataList.unshift(row)
      this.handleEdit(row, 0)
    },

    // 编辑
    handleEdit(row, index) {
      this.editInfo.id = row.id
      this.editInfo.symbol = row.symbol
      this.editInfo.status = row.status
      this.editIndex = index
    },

    // 保存
    handleSave(index) {
      // if (!this.editName || !this.editId || !this.editDepartment) {
      //   this.$Message.error('请填写完整的成员信息！')
      //   return
      // }

      if (this.loading) return
      if (this.submitting) return
      this.loading = true
      this.submitting = true

      const params = {
        id: this.editInfo.id,
        video_id: this.videoId,
        symbol: this.editInfo.symbol,
        status: this.editInfo.status,
      }
      // console.log(params)
      api.videoFragment
        .save(params)
        .then(() => {
          this.loading = false

          this.dataList[index].symbol = this.editInfo.symbol
          this.dataList[index].status = this.editInfo.status

          this.editIndex = -1
          this.addNew = false

          this.$Message.success('操作成功')
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.submitting = false
        })
    },

    // 取消编辑
    handleCancel() {
      this.editIndex = -1
      this.addNew = false
    },

    // 取消添加
    handleCancelAdd(index) {
      this.dataList.splice(index, 1)
      this.editIndex = -1
      this.addNew = false
    },

    // 打开资源设置
    handleOpenSource(video_fragment_id, quality) {
      // console.log(video_fragment_id, quality)

      api.videoFragment.source({ video_fragment_id, quality }).then((data) => {
        let sourceData = data.video_source || {
          id: 0,
          video_id: this.videoId,
          video_fragment_id,
          video_server_id: 0,
          quality,
          file_name: '',
          use_subtitle: 0,
          subtitles: '',
          default_subtitle: '',
          duration: '',
          front_duration: '',
          tail_duration: '',
          status: 1,
          front_start_duration: '',
          tail_end_duration: '',
        }

        this.subtitleList = sourceData.subtitles
          ? sourceData.subtitles.split(',')
          : []
        this.dataInfo = sourceData
        this.showSourceModal = true
        this.quality = quality
      })
    },
    handleSubmitSource() {
      // console.log(this.dataInfo)
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            video_id: this.dataInfo.video_id,
            video_fragment_id: this.dataInfo.video_fragment_id,
            video_server_id: this.dataInfo.video_server_id,
            quality: this.dataInfo.quality,
            file_name: this.dataInfo.file_name,
            use_subtitle: this.dataInfo.use_subtitle,
            subtitles: this.subtitleList.join(','),
            default_subtitle: this.dataInfo.default_subtitle,
            duration: this.dataInfo.duration,
            front_duration: this.dataInfo.front_duration,
            tail_duration: this.dataInfo.tail_duration,
            status: this.dataInfo.status,
            front_start_duration: this.dataInfo.front_start_duration,
            tail_end_duration: this.dataInfo.tail_end_duration,
          }
          // console.log(params)
          api.videoSource
            .save(params)
            .then(() => {
              this.loading = false

              this.showSourceModal = false
              this.$Message.success('修改成功')
              this.subtitleList = []
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },

    handleOpenCreateMultiple() {
      this.showCreateMultipleModal = true
    },
    handleCloseCreateMultiple() {
      this.showCreateMultipleModal = false
      this.handleGetDataList()
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
.video-pic {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 3px;

  > img {
    cursor: pointer;
  }
}
.video-fragment-qualities .ivu-btn:not(:first-child) {
  margin-left: 10px;
}
.video_list_fragment_detail .ivu-modal-body {
  overflow: visible !important;
}
.red_imp {
  input {
    color: #ff5722 !important;
  }
  .ivu-checkbox-wrapper-checked {
    color: #ff5722 !important;
    .ivu-checkbox-inner {
      background-color: #ff5722;
      border-color: #ff5722;
    }
  }
}
.video_list_details_open {
  margin-right: 20px;
}
</style>
