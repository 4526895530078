import request from './request'

export default {
  list(data) {
    return request.post('/paymentOrder/list', data)
  },
  info(data) {
    return request.post('/paymentOrder/info', data)
  },
  save(data) {
    return request.post('/paymentOrder/save', data)
  },
  enable(data) {
    return request.post('/paymentOrder/enable', data)
  },
  disable(data) {
    return request.post('/paymentOrder/disable', data)
  },
  forbidden(data) {
    return request.post('/paymentOrder/forbidden', data)
  },
  delete(data) {
    return request.post('/paymentOrder/delete', data)
  },
}
