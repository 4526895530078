import captcha from './captcha'
import passport from './passport'
import user from './user'
import ad from './ad'
import adCategory from './adCategory'
import country from './country'
import vipCard from './vipCard'
import vipCardCategory from './vipCardCategory'
import vipCardDetails from './vipCardDetails'
import vipCardManagement from './vipCardManagement'
import feedback from './feedback'
import paymentOrder from './paymentOrder'

import video from './video'
import videoFragment from './videoFragment'
import videoSource from './videoSource'
import videoCategory from './videoCategory'
import videoServer from './videoServer'
import videoComment from './videoComment'
import videoBarrage from './videoBarrage'
import videoReport from './videoReport'
import videoCollection from './videoCollection'

import paymentMethod from './paymentMethod'
import paymentGateway from './paymentGateway'
import paymentChannel from './paymentChannel'

import notice from './notice'
import faqCategory from './faqCategory'
import faq from './faq'
import banner from './banner'
import customerService from './customerService'
import page from './page'
import floating from './floating'
import appVersion from './appVersion'

import statistics from './statistics'
import summary from './summary'

import config from './config'
import admin from './admin'
import secrity from './secrity'
import rbac from './rbac'

import upload from './upload'

export default {
  captcha,
  passport,
  user,
  ad,
  adCategory,
  country,
  vipCard,
  vipCardCategory,
  vipCardDetails,
  vipCardManagement,
  feedback,
  paymentOrder,

  video,
  videoFragment,
  videoSource,
  videoCategory,
  videoServer,
  videoComment,
  videoBarrage,
  videoReport,
  videoCollection,

  paymentMethod,
  paymentGateway,
  paymentChannel,

  notice,
  faqCategory,
  faq,
  banner,
  customerService,
  page,
  floating,
  appVersion,

  statistics,
  summary,

  config,
  admin,
  secrity,
  rbac,

  upload,
}
