<template>
  <div>
    <Form ref="basicForm" :label-width="80" label-position="left">
      <Row>
        <Col :span="12">
          <FormItem prop="content" label="热门搜索">
            <div>
              <span v-for="(item, index) in keywordList" :key="index">
                <Input
                  v-if="currentEditKeywordIndex === index"
                  size="small"
                  class="ivu-mr-8"
                  v-model="currentEditKeywordContent"
                  v-width="100"
                  @on-enter="handleChangeKeyword(index)"
                  @on-blur="handleChangeKeyword(index)"
                ></Input>
                <Tag
                  v-else
                  @click.native="handleKeywordEdit(index)"
                  class="keyword"
                  color="primary"
                  closable
                  @on-close="handleKeywordClose(index)"
                >
                  {{ item }}
                </Tag>
              </span>

              <template v-if="keywordList.length < 10">
                <Input
                  v-model="newKeyword"
                  ref="keyword"
                  size="small"
                  v-if="addKeyword"
                  v-width="100"
                  @on-enter="handleAddNewKeyword"
                  @on-blur="addKeyword = false"
                />
                <Button
                  size="small"
                  type="dashed"
                  icon="md-add"
                  v-else
                  @click="handleOpenNewKeyword"
                />
              </template>
            </div>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col :span="12">
          <FormItem label="地区">
            <div>
              <span v-for="(item, index) in areaList" :key="index">
                <Input
                  v-if="currentEditAreaIndex === index"
                  size="small"
                  class="ivu-mr-8"
                  v-model="currentEditAreaContent"
                  v-width="100"
                  @on-enter="handleChangeArea(index)"
                  @on-blur="handleChangeArea(index)"
                ></Input>
                <Tag
                  v-else
                  @click.native="handleAreaEdit(index)"
                  class="keyword"
                  color="error"
                  closable
                  @on-close="handleAreaClose(index)"
                >
                  {{ item }}
                </Tag>
              </span>

              <Input
                v-model="newArea"
                ref="area"
                size="small"
                v-if="addArea"
                v-width="100"
                @on-enter="handleAddNewArea"
                @on-blur="addArea = false"
              />
              <Button
                size="small"
                type="dashed"
                icon="md-add"
                v-else
                @click="handleOpenNewArea"
              />
            </div>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col :span="12">
          <FormItem label="语言">
            <div>
              <span v-for="(item, index) in languageList" :key="index">
                <Input
                  v-if="currentEditLanguageIndex === index"
                  size="small"
                  class="ivu-mr-8"
                  v-model="currentEditLanguageContent"
                  v-width="100"
                  @on-enter="handleChangeLanguage(index)"
                  @on-blur="handleChangeLanguage(index)"
                ></Input>
                <Tag
                  v-else
                  @click.native="handleLanguageEdit(index)"
                  class="keyword"
                  color="warning"
                  closable
                  @on-close="handleLanguageClose(index)"
                >
                  {{ item }}
                </Tag>
              </span>

              <Input
                v-model="newLanguage"
                ref="language"
                size="small"
                v-if="addLanguage"
                v-width="100"
                @on-enter="handleAddNewLanguage"
                @on-blur="addLanguage = false"
              />
              <Button
                size="small"
                type="dashed"
                icon="md-add"
                v-else
                @click="handleOpenNewLanguage"
              />
            </div>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col :span="12">
          <FormItem label="年份">
            <div>
              <span v-for="(item, index) in yearList" :key="index">
                <Input
                  v-if="currentEditYearIndex === index"
                  size="small"
                  class="ivu-mr-8"
                  v-model="currentEditYearContent"
                  v-width="100"
                  @on-enter="handleChangeYear(index)"
                  @on-blur="handleChangeYear(index)"
                ></Input>
                <Tag
                  v-else
                  @click.native="handleYearEdit(index)"
                  class="keyword"
                  color="primary"
                  closable
                  @on-close="handleYearClose(index)"
                >
                  {{ item }}
                </Tag>
              </span>

              <Input
                v-model="newYear"
                ref="year"
                size="small"
                v-if="addYear"
                v-width="100"
                @on-enter="handleAddNewYear"
                @on-blur="addYear = false"
              />
              <Button
                size="small"
                type="dashed"
                icon="md-add"
                v-else
                @click="handleOpenNewYear"
              />
            </div>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col :span="12">
          <FormItem label="状态">
            <div>
              <span v-for="(item, index) in stateList" :key="index">
                <Input
                  v-if="currentEditStateIndex === index"
                  size="small"
                  class="ivu-mr-8"
                  v-model="currentEditStateContent"
                  v-width="100"
                  @on-enter="handleChangeState(index)"
                  @on-blur="handleChangeState(index)"
                ></Input>
                <Tag
                  v-else
                  @click.native="handleStateEdit(index)"
                  class="keyword"
                  color="error"
                  closable
                  @on-close="handleStateClose(index)"
                >
                  {{ item }}
                </Tag>
              </span>

              <Input
                v-model="newState"
                ref="state"
                size="small"
                v-if="addState"
                v-width="100"
                @on-enter="handleAddNewState"
                @on-blur="addState = false"
              />
              <Button
                size="small"
                type="dashed"
                icon="md-add"
                v-else
                @click="handleOpenNewState"
              />
            </div>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div>
      <Button type="primary" @click="handleSubmit" :loading="submitting">
        提交
      </Button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      currentEditKeywordIndex: null,
      currentEditKeywordContent: '',
      keywordList: [],
      addKeyword: false,
      newKeyword: '',

      currentEditAreaIndex: null,
      currentEditAreaContent: '',
      areaList: [],
      addArea: false,
      newArea: '',

      currentEditLanguageIndex: null,
      currentEditLanguageContent: '',
      languageList: [],
      addLanguage: false,
      newLanguage: '',

      currentEditYearIndex: null,
      currentEditYearContent: '',
      yearList: [],
      addYear: false,
      newYear: '',

      currentEditStateIndex: null,
      currentEditStateContent: '',
      stateList: [],
      addState: false,
      newState: '',

      submitting: false,
      loading: false,
    }
  },
  methods: {
    handleGetData() {
      api.config
        .info({
          symbol: 'video',
        })
        .then((data) => {
          // console.log(data)
          if (!data) return

          this.keywordList = data.keywords || []
          this.areaList = data.areas || []
          this.languageList = data.languages || []
          this.yearList = data.years || []
          this.stateList = data.states || []
        })
    },
    handleSubmit() {
      if (this.loading) return
      if (this.submitting) return
      this.loading = true
      this.submitting = true

      const params = {
        keywords: this.keywordList,
        areas: this.areaList,
        languages: this.languageList,
        years: this.yearList,
        states: this.stateList,
      }
      api.config
        .saveVideo(params)
        .then(() => {
          this.loading = false

          this.$Message.success('保存成功')
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.submitting = false
        })
    },
    // keyword
    handleKeywordEdit(i) {
      this.currentEditKeywordIndex = i
      this.currentEditKeywordContent = this.keywordList[i]
    },
    handleChangeKeyword(i) {
      this.currentEditKeywordIndex = null
      this.keywordList[i] = this.currentEditKeywordContent
    },
    handleKeywordClose(i) {
      this.keywordList.splice(i, 1)
    },
    handleOpenNewKeyword() {
      this.addKeyword = true
      this.$nextTick(() => {
        this.$refs.keyword.focus()
      })
    },
    handleAddNewKeyword() {
      if (this.newKeyword) {
        this.keywordList.push(this.newKeyword)
        this.newKeyword = ''
        this.addKeyword = false
      }
    },

    // area
    handleAreaEdit(i) {
      this.currentEditAreaIndex = i
      this.currentEditAreaContent = this.areaList[i]
    },
    handleChangeArea(i) {
      this.currentEditAreaIndex = null
      this.areaList[i] = this.currentEditAreaContent
    },
    handleAreaClose(i) {
      this.areaList.splice(i, 1)
    },
    handleOpenNewArea() {
      this.addArea = true
      this.$nextTick(() => {
        this.$refs.area.focus()
      })
    },
    handleAddNewArea() {
      if (this.newArea) {
        this.areaList.push(this.newArea)
        this.newArea = ''
        this.addArea = false
      }
    },

    // language
    handleLanguageEdit(i) {
      this.currentEditLanguageIndex = i
      this.currentEditLanguageContent = this.languageList[i]
    },
    handleChangeLanguage(i) {
      this.currentEditLanguageIndex = null
      this.languageList[i] = this.currentEditLanguageContent
    },
    handleLanguageClose(i) {
      this.languageList.splice(i, 1)
    },
    handleOpenNewLanguage() {
      this.addLanguage = true
      this.$nextTick(() => {
        this.$refs.language.focus()
      })
    },
    handleAddNewLanguage() {
      if (this.newLanguage) {
        this.languageList.push(this.newLanguage)
        this.newLanguage = ''
        this.addLanguage = false
      }
    },

    // year
    handleYearEdit(i) {
      this.currentEditYearIndex = i
      this.currentEditYearContent = this.yearList[i]
    },
    handleChangeYear(i) {
      this.currentEditYearIndex = null
      this.yearList[i] = this.currentEditYearContent
    },
    handleYearClose(i) {
      this.yearList.splice(i, 1)
    },
    handleOpenNewYear() {
      this.addYear = true
      this.$nextTick(() => {
        this.$refs.year.focus()
      })
    },
    handleAddNewYear() {
      if (this.newYear) {
        this.yearList.push(this.newYear)
        this.newYear = ''
        this.addYear = false
      }
    },

    // state
    handleStateEdit(i) {
      this.currentEditStateIndex = i
      this.currentEditStateContent = this.stateList[i]
    },
    handleChangeState(i) {
      this.currentEditStateIndex = null
      this.stateList[i] = this.currentEditStateContent
    },
    handleStateClose(i) {
      this.stateList.splice(i, 1)
    },
    handleOpenNewState() {
      this.addState = true
      this.$nextTick(() => {
        this.$refs.state.focus()
      })
    },
    handleAddNewState() {
      if (this.newState) {
        this.stateList.push(this.newState)
        this.newState = ''
        this.addState = false
      }
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>

<style lang="scss">
.keyword {
  cursor: pointer;
}
</style>
