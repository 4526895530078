<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="儿童站 - 合集管理" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt" ref="card">
      <o-filter ref="filter" @on-submit="handleGetData" />
      <o-table
        :categoryList="categoryList"
        ref="table"
        @on-fullscreen="handleFullscreen"
      />
    </Card>
  </div>
</template>
<script>
import screenfull from 'screenfull'
import oFilter from './filter'
import oTable from './table'
import api from '@/api'

export default {
  name: 'banner-list',
  components: {
    oFilter,
    oTable,
  },
  data() {
    return {
      categoryList: [],
    }
  },
  methods: {
    handleGetData() {
      this.handleGetCategoryList()
      this.$refs.table.handleGetDataList()
    },
    handleFullscreen(state) {
      if (state) {
        screenfull.request(this.$refs.card.$el)
      } else {
        screenfull.exit()
      }
    },
    // 取分类列表
    handleGetCategoryList() {
      api.video
        .category({
          // status: 1,
        })
        .then((data) => {
          if (!data) return
          this.categoryList = data.video_category_list || []
        })
        .catch(() => {})
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
