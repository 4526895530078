var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ButtonGroup',{attrs:{"size":"small"}},[_c('Button',{attrs:{"disabled":_vm.addNew,"type":"primary"},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")])],1),_c('ButtonGroup',{staticClass:"ivu-ml-8",attrs:{"size":"small"}},[_c('Button',{attrs:{"type":"success","disabled":_vm.selectedIds.length < 1},on:{"click":_vm.handleEnableMultiple}},[_vm._v(" 启用 ")]),_c('Button',{attrs:{"type":"warning","disabled":_vm.selectedIds.length < 1},on:{"click":_vm.handleDisableMultiple}},[_vm._v(" 禁用 ")]),_c('Button',{attrs:{"type":"error","disabled":_vm.selectedIds.length < 1},on:{"click":_vm.handleDeleteMultiple}},[_vm._v(" 删除 ")])],1),_c('div',{staticClass:"ivu-inline-block ivu-fr"},[_c('Tooltip',{staticClass:"ivu-ml",attrs:{"content":_vm.tableFullscreen ? '退出全屏' : '全屏',"placement":"top"}},[_c('i-link',{nativeOn:{"click":function($event){return _vm.handleFullscreen($event)}}},[_c('Icon',{attrs:{"custom":_vm.tableFullscreen
              ? 'i-icon i-icon-exit-full-screen'
              : 'i-icon i-icon-full-screen'}})],1)],1),_c('Tooltip',{staticClass:"ivu-ml",attrs:{"content":"刷新","placement":"top"}},[_c('i-link',{nativeOn:{"click":function($event){return _vm.handleRefresh($event)}}},[_c('Icon',{attrs:{"custom":"i-icon i-icon-refresh"}})],1)],1),_c('Dropdown',{attrs:{"trigger":"click"}},[_c('Tooltip',{staticClass:"ivu-ml",attrs:{"content":"列设置","placement":"top"}},[_c('i-link',[_c('Icon',{attrs:{"type":"md-options"}})],1)],1),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[_c('div',{staticClass:"ivu-p-8"},[_vm._v("列展示")]),_c('Divider',{staticClass:"ivu-mt-8 ivu-mb-8",attrs:{"size":"small"}}),_vm._l((_vm.columns),function(item){return [(item.title)?_c('li',{key:item.title,staticClass:"ivu-dropdown-item",on:{"click":function($event){item.show = !item.show}}},[_c('Checkbox',{model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}}),_c('span',[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2)],1)],1),_c('Alert',{staticClass:"ivu-mt",attrs:{"show-icon":""}},[_c('p',[_vm._v("字幕文件访问规则与视频文件访问规则相同, 只是后缀不同")]),_c('p',[_vm._v("字幕文件需要跟 m3u8 文件放在同一个目录下, 如:")]),_c('p',[_vm._v(" 有视频路径为 xxxxxx.mp4/index.m3u8, 则字幕文件路径为 xxxxxx.mp4/[lang].vtt, 其中 [lang] 是语言名称占位符 ")]),_c('p',[_vm._v("网页播放器目前只支持 webvtt 格式的字幕, 即 .vtt 后缀的文件")])]),_c('Table',{ref:"table",staticClass:"ivu-mt",attrs:{"columns":_vm.tableColumns,"data":_vm.dataList,"loading":_vm.loading},on:{"on-select":_vm.handleSelect,"on-select-cancel":_vm.handleSelectCancel,"on-select-all":_vm.handleSelectAll,"on-select-all-cancel":_vm.handleClearSelect},scopedSlots:_vm._u([{key:"name",fn:function(ref){
              var row = ref.row;
              var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text","size":"small"},model:{value:(_vm.editInfo.name),callback:function ($$v) {_vm.$set(_vm.editInfo, "name", $$v)},expression:"editInfo.name"}}):_c('span',[_vm._v(_vm._s(row.name))])]}},{key:"prefix",fn:function(ref){
              var row = ref.row;
              var index = ref.index;
return [(_vm.editIndex === index)?[_c('Select',{directives:[{name:"width",rawName:"v-width",value:(80),expression:"80"}],attrs:{"size":"small","placeholder":"协议","transfer":""},model:{value:(_vm.editInfo.scheme),callback:function ($$v) {_vm.$set(_vm.editInfo, "scheme", $$v)},expression:"editInfo.scheme"}},[_c('Option',{attrs:{"value":1}},[_vm._v("http://")]),_c('Option',{attrs:{"value":2}},[_vm._v("https://")])],1),_c('Input',{directives:[{name:"width",rawName:"v-width",value:(175),expression:"175"}],attrs:{"size":"small","placeholder":"主机"},model:{value:(_vm.editInfo.host),callback:function ($$v) {_vm.$set(_vm.editInfo, "host", $$v)},expression:"editInfo.host"}}),_vm._v(" : "),_c('Input',{directives:[{name:"width",rawName:"v-width",value:(55),expression:"55"}],attrs:{"type":"text","size":"small","placeholder":"端口","maxlength":"5"},model:{value:(_vm.editInfo.port),callback:function ($$v) {_vm.$set(_vm.editInfo, "port", $$v)},expression:"editInfo.port"}}),_c('Input',{directives:[{name:"width",rawName:"v-width",value:(150),expression:"150"}],attrs:{"type":"text","size":"small","placeholder":"路径"},model:{value:(_vm.editInfo.base_url),callback:function ($$v) {_vm.$set(_vm.editInfo, "base_url", $$v)},expression:"editInfo.base_url"}}),_vm._v(" /[文件名][相应后缀] ")]:_c('div',[_c('div',[_c('Tag',{attrs:{"color":"blue"}},[_vm._v("视频")]),_vm._v(" "+_vm._s(_vm.schemeLabel(row.scheme))+_vm._s(row.host)+_vm._s(_vm.portLabel(row.port, row.scheme))+_vm._s(row.base_url)+"/[文件名]"+_vm._s(row.video_suffix)+" ")],1),_c('div',[_c('Tag',{attrs:{"color":"orange"}},[_vm._v("字幕")]),_vm._v(" "+_vm._s(_vm.schemeLabel(row.scheme))+_vm._s(row.host)+_vm._s(_vm.portLabel(row.port, row.scheme))+_vm._s(row.base_url)+"/[文件名]"+_vm._s(row.subtitle_suffix)+" ")],1)])]}},{key:"video_suffix",fn:function(ref){
              var row = ref.row;
              var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text","size":"small","placeholder":"视频后缀"},model:{value:(_vm.editInfo.video_suffix),callback:function ($$v) {_vm.$set(_vm.editInfo, "video_suffix", $$v)},expression:"editInfo.video_suffix"}}):[_vm._v(_vm._s(row.video_suffix))]]}},{key:"subtitle_suffix",fn:function(ref){
              var row = ref.row;
              var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text","size":"small","placeholder":"字幕后缀"},model:{value:(_vm.editInfo.subtitle_suffix),callback:function ($$v) {_vm.$set(_vm.editInfo, "subtitle_suffix", $$v)},expression:"editInfo.subtitle_suffix"}}):[_vm._v(_vm._s(row.subtitle_suffix))]]}},{key:"status",fn:function(ref){
              var row = ref.row;
              var index = ref.index;
return [(_vm.editIndex === index)?_c('i-switch',{attrs:{"size":"small","true-value":1,"false-value":0},model:{value:(_vm.editInfo.status),callback:function ($$v) {_vm.$set(_vm.editInfo, "status", $$v)},expression:"editInfo.status"}}):[(row.status === 1)?_c('Badge',{attrs:{"color":"green","text":"正常"}}):(row.status === 0)?_c('Badge',{attrs:{"color":"yellow","text":"禁用"}}):_vm._e()]]}},{key:"action",fn:function(ref){
              var row = ref.row;
              var index = ref.index;
return [(_vm.editIndex === index)?[_c('ButtonGroup',{attrs:{"size":"small"}},[_c('Button',{attrs:{"type":"success","ghost":""},on:{"click":function($event){return _vm.handleSave(index)}}},[(!_vm.addNew)?[_vm._v("保存")]:[_vm._v("添加")]],2),(!_vm.addNew)?_c('Button',{attrs:{"type":"primary","ghost":""},on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")]):_c('Button',{attrs:{"type":"error","ghost":"","size":"small"},on:{"click":function($event){return _vm.handleCancelAdd(index)}}},[_vm._v(" 删除 ")])],1)]:[_c('ButtonGroup',{attrs:{"size":"small"}},[_c('Button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){return _vm.handleEdit(row, index)}}},[_vm._v(" 编辑 ")]),_c('Button',{attrs:{"type":"error","ghost":""},on:{"click":function($event){return _vm.handleDelete(row.id)}}},[_vm._v(" 删除 ")])],1)]]}}])}),_c('div',{staticClass:"ivu-mt ivu-text-center"},[_c('Page',{attrs:{"size":"small","total":_vm.dataTotal,"current":_vm.page,"page-size-opts":[10, 20, 50, 100],"show-total":"","show-sizer":"","show-elevator":"","page-size":_vm.pagesize},on:{"update:current":function($event){_vm.page=$event},"on-change":_vm.handleChangePage,"on-page-size-change":_vm.handleChangePageSize}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }