<template>
  <div>
    <ButtonGroup size="small">
      <Button type="primary" @click="handleOpenCreate">新增</Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        启用
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        禁用
      </Button>
      <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button>
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="platform">
        <Tag color="blue">{{ platformLabel(row.platform) }}</Tag>
      </template>
      <template slot-scope="{ row }" slot="category">
        <Tag color="blue">{{ categoryName(row.category_id) }}</Tag>
      </template>
      <template slot-scope="{ row }" slot="type">
        <span>{{ typeLabel(row.type) }}</span>
      </template>
      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="正常" />
        <Badge v-else-if="row.status === 0" color="yellow" text="禁用" />
      </template>
      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button type="primary" ghost @click="handleEdit(row.id)">
            编辑
          </Button>
          <Button
            type="primary"
            :disabled="!!row.is_all_country"
            ghost
            @click="toCountry(row.id)"
          >
            关联
          </Button>
          <Button type="error" ghost @click="handleDelete(row.id)">
            删除
          </Button>
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '儿童站 - 编辑广告'
          : dataDrawer.type === 'new'
          ? '儿童站 - 添加广告'
          : '儿童站 - 广告信息'
      "
      width="540"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="platform" label="平台">
              <Select
                size="small"
                v-model="dataInfo.platform"
                clearable
                placeholder="请选择平台"
              >
                <Option
                  v-for="(item, index) in platforms"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.label }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="category_id" label="广告位">
              <Select
                size="small"
                v-model="dataInfo.category_id"
                clearable
                placeholder="请选择广告位"
              >
                <Option
                  v-for="item in allowCategoryList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <Alert type="warning" v-if="dataInfo.category_id">
              {{ selectCategoryTip(dataInfo.category_id) }}
            </Alert>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="title" label="标题">
              <Input
                size="small"
                v-model="dataInfo.title"
                placeholder="请输入标题"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="source" label="资源地址">
              <Input
                size="small"
                v-model="dataInfo.source"
                placeholder="请输入资源地址"
              >
                <Upload
                  slot="append"
                  action=""
                  :show-upload-list="false"
                  :before-upload="handleUploadSource"
                >
                  <Button
                    size="small"
                    type="primary"
                    style="background-color: #2d8cf0; color: #fff"
                  >
                    上传
                  </Button>
                </Upload>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="url" label="跳转地址">
              <Input
                size="small"
                v-model="dataInfo.url"
                placeholder="请输入跳转地址"
              />
            </FormItem>
          </Col>
        </Row>

        <!-- <Row :gutter="32">
          <Col span="24">
            <FormItem label="广告关联国家">
              <CheckboxGroup
                size="small"
                v-if="countryList"
                v-model="dataInfo.country_list"
              >
                <Checkbox
                  v-for="(item2, index2) in countryList"
                  :key="index2"
                  :label="item2.id"
                  class="ivu-mr"
                >
                  {{ item2.name_cn }}
                </Checkbox>
              </CheckboxGroup>
            </FormItem>
          </Col>
        </Row> -->

        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="duration" label="广告展示时间(秒)">
              <Input
                size="small"
                v-model="dataInfo.duration"
                placeholder="请输入至少观看时间"
                maxlength="4"
              />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="sort" label="排序">
              <Input
                size="small"
                v-model="dataInfo.sort"
                placeholder="-99 ~ 99"
                maxlength="3"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="status" label="状态">
              <RadioGroup size="small" v-model="dataInfo.status">
                <Radio :label="1"><span>正常</span></Radio>
                <Radio :label="0"><span>禁用</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="is_all_country" label="国家显示">
              <RadioGroup size="small" v-model="dataInfo.is_all_country">
                <Radio :label="1"><span>全部显示</span></Radio>
                <Radio :label="0"><span>指定关联</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <!-- <Row :gutter="32">
          <Col span="12">
            <FormItem prop="is_night" label="是否午夜版">
              <RadioGroup size="small" v-model="dataInfo.is_night">
                <Radio :label="1"><span>是</span></Radio>
                <Radio :label="0"><span>否</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="is_children" label="是否儿童版">
              <RadioGroup size="small" v-model="dataInfo.is_children">
                <Radio :label="1"><span>是</span></Radio>
                <Radio :label="0"><span>否</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row> -->
        <FormItem prop="remark" label="备注">
          <Input
            size="small"
            v-model="dataInfo.remark"
            placeholder="请输入备注"
          />
        </FormItem>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <div class="page-sys-footer-copy">
          <Button
            type="primary"
            @click="copyJSON('advCopy')"
            :loading="submitting"
          >
            拓
          </Button>
          <Button @click="paste('advCopy')" :loading="submitting">
            印
          </Button>
        </div>
        <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>
    <Modal
      v-model="showCountry"
      title="关联国家"
      width="800"
      :mask-closable="false"
      footer-hide
    >
      <Form label="国家列表">
        <CheckboxGroup v-model="country_list" v-if="countryList">
          <Checkbox
            :label="item.id"
            :key="index"
            v-for="(item, index) in countryList"
          >
            <span>{{ item.name_cn }}</span>
          </Checkbox>
        </CheckboxGroup>
      </Form>
      <Form class="counry-bottom">
        <Button type="primary" @click="putCountry()">确定</Button>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'

import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'

export default {
  props: {
    platforms: {
      type: Array,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    categoryList: {
      type: Array,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      datetimeFormat,
      columns: tableColumns,
      showCountry: false,
      country_list: [],
      countryID: null,
      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },
    }
  },
  mixins: [dataListMixin],
  computed: {
    allowCategoryList() {
      return this.categoryList.filter(
        (item) => item.platform === this.dataInfo.platform,
      )
    },
  },
  methods: {
    copyJSON(name) {
      const text = { name: name, json: JSON.stringify(this.dataInfo) }
      this.$Copy({
        text: JSON.stringify(text),
      })
    },
    paste(name) {
      navigator.clipboard
        .readText()
        .then((textjson) => {
          try {
            if (!textjson) return

            const text = JSON.parse(textjson)
            if (text.name != name) {
              throw new Error('该数据不是当前页面数据')
            }
            if (text && text.name && text.json && text.name == name) {
              let jsonObj = JSON.parse(text.json)
              jsonObj.id = 0
              delete jsonObj.create_time
              jsonObj.is_children = 1
              jsonObj.is_night = 0
              this.dataInfo = jsonObj
              this.$Message.success('粘贴成功')
            }
          } catch {
            this.$Message.error(`粘贴错误，数据有误，请检查`)
          }
        })
        .catch((err) => {
          console.error('Failed to read clipboard contents: ', err)
        })
      name
    },
    async putCountry() {
      this.loading = true
      api.ad
        .setCountry({
          countries: this.country_list,
          id: this.countryID,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('关联国家成功')
          this.showCountry = false
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 关联国家
    toCountry(id) {
      this.getCounryOne(id)
        .then(() => {
          this.showCountry = true
          this.countryID = id
        })
        .catch(() => {})
    },
    categoryName(id) {
      for (const i in this.categoryList) {
        if (this.categoryList[i].id === id) {
          return this.categoryList[i].name
        }
      }
      return '--'
    },
    platformLabel(value) {
      for (const i in this.platforms) {
        if (this.platforms[i].value === value) {
          return this.platforms[i].label
        }
      }
      return '--'
    },
    typeLabel(value) {
      for (const i in this.types) {
        if (this.types[i].value === value) {
          return this.types[i].label
        }
      }
      return '--'
    },
    selectCategoryTip(id) {
      if (!id) return ''

      let text = ''
      for (const i in this.categoryList) {
        if (this.categoryList[i].id === id) {
          switch (this.categoryList[i].type) {
            case 1:
              text = `当前所选广告位类型为: 图片, 尺寸: ${this.categoryList[i].width} * ${this.categoryList[i].height} px (等比例即可)`
              break
            case 2:
              text = '当前所选广告位类型为: 视频'
              break
            case 3:
              text = '当前所选广告位类型为: 文字'
              break
          }
          break
        }
      }
      return text
    },
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      const {
        keyword,
        platform,
        type,
        category_id,
        status,
        page,
        is_night = '0',
        is_children = '1',
      } = this.$parent.$parent.$refs.filter.params
      if (page) {
        this.page = page
        this.$parent.$parent.$refs.filter.params.page = null
      }
      api.ad
        .list({
          keyword,
          platform,
          type,
          is_children,
          is_night,
          category_id,
          status,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.ad_list || []
          this.dataTotal = data.ad_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.ad
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.ad || null
            this.selectedCategoryId = data.ad.category_id
            resolve()
            // api.ad
            //   .countryInfo({
            //     id,
            //   })
            //   .then((data2) => {
            //     this.dataInfo.country_list = []
            //     if (!data2) return
            //     if (data2.country_list) {
            //       this.dataInfo.country_list = data2.country_list
            //     }

            //   })
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 取单一国家数据
    getCounryOne(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.ad
          .countryInfo({
            id,
          })
          .then((data) => {
            this.country_list = []
            if (!data) return
            if (data.country_list) {
              data.country_list.forEach((e) => {
                this.country_list.push(e.id)
              })
            }
            // this.country_list = data.country_list || []
            resolve()
            // api.ad
            //   .countryInfo({
            //     id,
            //   })
            //   .then((data2) => {
            //     this.dataInfo.country_list = []
            //     if (!data2) return
            //     if (data2.country_list) {
            //       this.dataInfo.country_list = data2.country_list
            //     }

            //   })
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.ad
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量封号
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.ad
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.ad
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.ad
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },
    // 添加
    handleOpenCreate() {
      this.dataInfo = {
        id: 0,
        category_id: 0,
        // type: 0,
        title: '',
        source: '',
        url: '',
        duration: 0,
        sort: 50,
        status: 1,
        is_all_country: 1,
        remark: '',
        is_night: 0,
        is_children: 1,
      }

      this.dataInfoString = this.createDataInfoString(this.dataInfo)
      this.dataDrawer.type = 'new'
      this.dataDrawer.show = true
      this.handleInitDataInfoForm()
    },
    handleSubmitEdit() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            category_id: this.dataInfo.category_id,
            // type: this.dataInfo.type,
            title: this.dataInfo.title,
            source: this.dataInfo.source,
            url: this.dataInfo.url,
            duration: this.dataInfo.duration,
            sort: this.dataInfo.sort,
            status: this.dataInfo.status,
            is_all_country: this.dataInfo.is_all_country,
            remark: this.dataInfo.remark,
            is_night: this.dataInfo.is_night,
            is_children: this.dataInfo.is_children,
          }
          // console.log(params)
          api.ad
            .save(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },

    handleUploadSource(file) {
      let params = new FormData()
      params.append('image', file)
      // params.append('width', 72)
      // params.append('height', 72)

      api.upload.adPoster(params).then((data) => {
        this.dataInfo.source = data.url
      })

      return false
    },
  },
}
</script>
<style lang="scss">
.counry-bottom {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid #e8eaec;
}
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
