import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

const pre = 'son-'

export default {
  path: '/son',
  name: 'son',
  // redirect: {
  //   name: `${pre}wybanner`,
  // },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'wybanner',
      name: `${pre}wybanner`,
      meta: {
        ...meta,
        title: '午夜版-海报轮播',
      },
      component: () => import('@/views/son/wybanner'),
    },
    {
      path: 'wyguanggao',
      name: `${pre}wyguanggao`,
      meta: {
        ...meta,
        title: '午夜版-广告管理',
      },
      component: () => import('@/views/son/wyguanggao'),
    },
    {
      path: 'childbanner',
      name: `${pre}childbanner`,
      meta: {
        ...meta,
        title: '儿童版-海报轮播',
      },
      component: () => import('@/views/son/childbanner'),
    },
    {
      path: 'childguanggao',
      name: `${pre}childguanggao`,
      meta: {
        ...meta,
        title: '儿童版-广告管理',
      },
      component: () => import('@/views/son/childguanggao'),
    },
    {
      path: 'childcollect',
      name: `${pre}childcollect`,
      meta: {
        ...meta,
        title: '儿童版-资源合集',
      },
      component: () => import('@/views/son/childcollect'),
    },
  ],
}
