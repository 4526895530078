<template>
  <Row :gutter="24">
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false" dis-hover :padding="12">
        <p slot="title">
          <span>订单</span>
        </p>
        <Tabs type="card" size="small">
          <TabPane label="本日">
            <Row>
              <Col span="16">
                <Numeral
                  :value="summary.payment_order_amount_increment_today"
                  prefix="$"
                  format="0,0"
                  v-font="30"
                />
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend
                      :flag="
                        paymentOrderAmountIncrementDailyGrowth > 0
                          ? 'up'
                          : 'down'
                      "
                    >
                      {{
                        paymentOrderAmountIncrementDailyGrowth !== null
                          ? Math.abs(
                              paymentOrderAmountIncrementDailyGrowth,
                            ).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
              <Col span="8" class="ivu-text-right">
                <Numeral
                  :value="summary.payment_order_count_increment_today"
                  format="0,0"
                  v-font="30"
                >
                  <span slot="suffix" v-font="16">笔</span>
                </Numeral>
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend
                      :flag="
                        paymentOrderCountIncrementDailyGrowth > 0
                          ? 'up'
                          : 'down'
                      "
                    >
                      {{
                        paymentOrderCountIncrementDailyGrowth !== null
                          ? Math.abs(
                              paymentOrderCountIncrementDailyGrowth,
                            ).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">客单价</Col>
              <Col span="12" class="ivu-text-right">
                <Numeral
                  prefix="$"
                  :value="
                    paymentOrderPerAmountToday !== null
                      ? paymentOrderPerAmountToday
                      : '--'
                  "
                  format="0,0.00"
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane label="本月">
            <Row>
              <Col span="16">
                <Numeral
                  :value="summary.payment_order_amount_increment_this_month"
                  prefix="$"
                  format="0,0.00"
                  v-font="30"
                />
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend
                      :flag="
                        paymentOrderAmountIncrementMonthlyGrowth > 0
                          ? 'up'
                          : 'down'
                      "
                    >
                      {{
                        paymentOrderAmountIncrementMonthlyGrowth !== null
                          ? Math.abs(
                              paymentOrderAmountIncrementMonthlyGrowth,
                            ).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
              <Col span="8" class="ivu-text-right">
                <Numeral
                  :value="summary.payment_order_count_increment_this_month"
                  format="0,0"
                  v-font="30"
                >
                  <span slot="suffix" v-font="16">笔</span>
                </Numeral>
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend
                      :flag="
                        paymentOrderCountIncrementMonthlyGrowth > 0
                          ? 'up'
                          : 'down'
                      "
                    >
                      {{
                        paymentOrderCountIncrementMonthlyGrowth !== null
                          ? Math.abs(
                              paymentOrderCountIncrementMonthlyGrowth,
                            ).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">客单价</Col>
              <Col span="12" class="ivu-text-right">
                <Numeral
                  prefix="$"
                  :value="
                    paymentOrderPerAmountThisMonth !== null
                      ? paymentOrderPerAmountThisMonth
                      : '--'
                  "
                  format="0,0.00"
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
    </Col>

    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false" dis-hover :padding="12">
        <p slot="title">
          <span>活跃 / 付费</span>
        </p>
        <Tabs type="card" size="small">
          <TabPane label="本日">
            <Row>
              <Col span="12">
                <span>活跃</span>
                <Numeral
                  :value="summary.user_active_today"
                  format="0,0"
                  v-font="30"
                >
                  <span slot="suffix" v-font="16">个</span>
                </Numeral>
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend :flag="userActiveDailyGrowth > 0 ? 'up' : 'down'">
                      {{
                        userActiveDailyGrowth !== null
                          ? Math.abs(userActiveDailyGrowth).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
              <Col span="12" class="ivu-text-right">
                <span>付费</span>
                <Numeral
                  :value="summary.user_paid_today"
                  format="0,0"
                  v-font="30"
                >
                  <span slot="suffix" v-font="16">个</span>
                </Numeral>
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend :flag="userPaidDailyGrowth > 0 ? 'up' : 'down'">
                      {{
                        userPaidDailyGrowth !== null
                          ? Math.abs(userPaidDailyGrowth).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">付费率</Col>
              <Col span="12" class="ivu-text-right">
                <Numeral
                  suffix="%"
                  :value="payRateToday !== null ? payRateToday : '--'"
                  format="0,0.00"
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane label="本月">
            <Row>
              <Col span="12">
                <span>活跃</span>
                <Numeral
                  :value="summary.user_active_this_month"
                  format="0,0"
                  v-font="30"
                >
                  <span slot="suffix" v-font="16">个</span>
                </Numeral>
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend
                      :flag="userActiveMonthlyGrowth > 0 ? 'up' : 'down'"
                    >
                      {{
                        userActiveMonthlyGrowth !== null
                          ? Math.abs(userActiveMonthlyGrowth).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
              <Col span="12" class="ivu-text-right">
                <span>付费</span>
                <Numeral
                  :value="summary.user_paid_this_month"
                  format="0,0"
                  v-font="30"
                >
                  <span slot="suffix" v-font="16">个</span>
                </Numeral>
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend :flag="userPaidMonthlyGrowth > 0 ? 'up' : 'down'">
                      {{
                        userPaidMonthlyGrowth !== null
                          ? Math.abs(userPaidMonthlyGrowth).toFixed(2) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">付费率</Col>
              <Col span="12" class="ivu-text-right">
                <Numeral
                  suffix="%"
                  :value="payRateThisMonth !== null ? payRateThisMonth : '--'"
                  format="0,0.00"
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
    </Col>

    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false" dis-hover :padding="12">
        <p slot="title">
          <span>新增用户</span>
        </p>
        <Tabs type="card" size="small">
          <TabPane label="本日">
            <Row>
              <Col span="24">
                <Numeral
                  :value="summary.user_count_increment_today"
                  format="0,0"
                  v-font="30"
                />
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend
                      :flag="userCountIncrementDailyGrowth > 0 ? 'up' : 'down'"
                    >
                      {{
                        userCountIncrementDailyGrowth !== null
                          ? Math.abs(userCountIncrementDailyGrowth).toFixed(2) +
                            '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">总用户</Col>
              <Col span="12" class="ivu-text-right">
                <Numeral :value="summary.user_total" format="0,0" />
              </Col>
            </Row>
          </TabPane>
          <TabPane label="本月">
            <Row>
              <Col span="24">
                <Numeral
                  :value="summary.user_count_increment_this_month"
                  format="0,0"
                  v-font="30"
                />
                <div class="ivu-pt-8" v-height="42">
                  <span>
                    环比
                    <Trend
                      :flag="
                        userCountIncrementMonthlyGrowth > 0 ? 'up' : 'down'
                      "
                    >
                      {{
                        userCountIncrementMonthlyGrowth !== null
                          ? Math.abs(userCountIncrementMonthlyGrowth).toFixed(
                              2,
                            ) + '%'
                          : '--'
                      }}
                    </Trend>
                  </span>
                </div>
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">总用户</Col>
              <Col span="12" class="ivu-text-right">
                <Numeral :value="summary.user_total" format="0,0" />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
    </Col>

    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false" dis-hover :padding="12">
        <p slot="title">
          <span>APP下载点击量</span>
        </p>
        <Tabs type="card" size="small">
          <TabPane label="本日">
            <Row class="app-info">
              <Col span="12">
                <Icon type="logo-android" size="40" />
                <CountUp
                  :end="summary.android_download_today"
                  :duration="3"
                  v-font="26"
                  format="0,0"
                />
              </Col>
              <Col span="12">
                <Icon type="logo-apple" size="40" />
                <CountUp
                  :end="summary.ios_download_today"
                  :duration="3"
                  v-font="26"
                  format="0,0"
                />
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">总下载量</Col>
              <Col span="12" class="ivu-text-right">
                <Icon type="logo-android" size="24" />
                <Numeral
                  :value="summary.android_download_total"
                  format="0,0"
                />
                <Divider type="vertical" />
                <Icon type="logo-apple" size="24" />
                <Numeral :value="summary.ios_download_total" format="0,0" />
              </Col>
            </Row>
          </TabPane>
          <TabPane label="本月">
            <Row class="app-info">
              <Col span="12">
                <Icon type="logo-android" size="40" />
                <CountUp
                  :end="summary.android_download_this_month"
                  :duration="3"
                  v-font="26"
                  format="0,0"
                />
              </Col>
              <Col span="12">
                <Icon type="logo-apple" size="40" />
                <CountUp
                  :end="summary.ios_download_this_month"
                  :duration="3"
                  v-font="26"
                  format="0,0"
                />
              </Col>
            </Row>
            <Divider style="margin: 8px 0" />
            <Row>
              <Col span="12">总下载量</Col>
              <Col span="12" class="ivu-text-right">
                <Icon type="logo-android" size="24" />
                <Numeral
                  :value="summary.android_download_total"
                  format="0,0"
                />
                <Divider type="vertical" />
                <Icon type="logo-apple" size="24" />
                <Numeral :value="summary.ios_download_total" format="0,0" />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <!-- <Row>
          <Col span="24">
            <CountUp :end="ios_download_today" :duration="3" v-font="30" />
          </Col>
        </Row> -->

        <!-- <div style="margin-top: 76px"></div>
        <Divider style="margin: 8px 0" />
        <Row>
          <Col span="12">历史最高</Col>
          <Col span="12" class="ivu-text-right">
            <CountUp :end="maxOnlineUserTotal" :duration="3" />
          </Col>
        </Row> -->
      </Card>
    </Col>
  </Row>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      grid: {
        xl: 6,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },

      summary: {
        user_count_increment_today: 0,
        payment_order_count_increment_today: 0,
        payment_order_amount_increment_today: 0,

        user_count_increment_this_month: 0,
        payment_order_count_increment_this_month: 0,
        payment_order_amount_increment_this_month: 0,

        user_total: 0,

        android_download_this_month: 0,
        android_download_today: 0,
        android_download_total: 0,
        ios_download_this_month: 0,
        ios_download_today: 0,
        ios_download_total: 0,
      },
      statistics_yesterday: {
        user_increment_1d: 0,
        user_active_1d: 0,
        user_paid_1d: 0,
        payment_order_count_increment_1d: 0,
        payment_order_amount_increment_1d: 0,
      },
      statistics_last_month: {
        user_increment: 0,
        user_active: 0,
        user_paid: 0,
        payment_order_count_increment: 0,
        payment_order_amount_increment: 0,
      },

      onlineUserTimer: null,
      onlineUserTotal: 0,
      maxOnlineUserTotal: 0,
    }
  },
  computed: {
    // 本日客单价
    paymentOrderPerAmountToday() {
      if (this.summary.payment_order_count_increment_today <= 0) {
        return null
      }
      const res = (
        this.summary.payment_order_amount_increment_today /
        this.summary.payment_order_count_increment_today
      ).toFixed(2)
      return res
    },
    // 本月客单价
    paymentOrderPerAmountThisMonth() {
      if (this.summary.payment_order_count_increment_this_month <= 0) {
        return null
      }
      const res = (
        this.summary.payment_order_amount_increment_this_month /
        this.summary.payment_order_count_increment_this_month
      ).toFixed(2)
      return res
    },
    // 活跃用户日环比
    userActiveDailyGrowth() {
      if (this.statistics_yesterday.user_active_1d <= 0) {
        return null
      }
      const res =
        ((this.summary.user_active_today -
          this.statistics_yesterday.user_active_1d) /
          this.statistics_yesterday.user_active_1d) *
        100
      return res
    },
    // 活跃用户月环比
    userActiveMonthlyGrowth() {
      if (this.statistics_last_month.user_active <= 0) {
        return null
      }
      const res =
        ((this.summary.user_active_this_month -
          this.statistics_last_month.user_active) /
          this.statistics_last_month.user_active) *
        100
      return res
    },
    // 付费用户日环比
    userPaidDailyGrowth() {
      if (this.statistics_yesterday.user_paid_1d <= 0) {
        return null
      }
      const res =
        ((this.summary.user_paid_today -
          this.statistics_yesterday.user_paid_1d) /
          this.statistics_yesterday.user_paid_1d) *
        100
      return res
    },
    // 付费用户月环比
    userPaidMonthlyGrowth() {
      if (this.statistics_last_month.user_paid <= 0) {
        return null
      }
      const res =
        ((this.summary.user_paid_this_month -
          this.statistics_last_month.user_paid) /
          this.statistics_last_month.user_paid) *
        100
      return res
    },
    // 本日付费率
    payRateToday() {
      if (this.summary.user_active_today <= 0) {
        return null
      }
      const res = (
        (this.summary.user_paid_today / this.summary.user_active_today) *
        100
      ).toFixed(2)
      return res
    },
    // 本月付费率
    payRateThisMonth() {
      if (this.summary.user_active_this_month <= 0) {
        return null
      }
      const res = (
        (this.summary.user_paid_this_month /
          this.summary.user_active_this_month) *
        100
      ).toFixed(2)
      return res
    },
    // 新增用户数量日环比
    userCountIncrementDailyGrowth() {
      if (this.statistics_yesterday.user_increment_1d <= 0) {
        return null
      }
      const res =
        ((this.summary.user_count_increment_today -
          this.statistics_yesterday.user_increment_1d) /
          this.statistics_yesterday.user_increment_1d) *
        100
      return res
    },
    // 新增用户数量月环比
    userCountIncrementMonthlyGrowth() {
      if (this.statistics_last_month.user_increment <= 0) {
        return null
      }
      const res =
        ((this.summary.user_count_increment_this_month -
          this.statistics_last_month.user_increment) /
          this.statistics_last_month.user_increment) *
        100
      return res
    },
    // 新增订单金额日环比
    paymentOrderAmountIncrementDailyGrowth() {
      if (this.statistics_yesterday.payment_order_amount_increment_1d <= 0) {
        return null
      }
      const res =
        ((this.summary.payment_order_amount_increment_today -
          this.statistics_yesterday.payment_order_amount_increment_1d) /
          this.statistics_yesterday.payment_order_amount_increment_1d) *
        100
      return res
    },
    // 新增订单金额月环比
    paymentOrderAmountIncrementMonthlyGrowth() {
      if (this.statistics_last_month.payment_order_amount_increment <= 0) {
        return null
      }
      const res =
        ((this.summary.payment_order_amount_increment_this_month -
          this.statistics_last_month.payment_order_amount_increment) /
          this.statistics_last_month.payment_order_amount_increment) *
        100
      return res
    },
    // 新增订单数量日环比
    paymentOrderCountIncrementDailyGrowth() {
      if (this.statistics_yesterday.payment_order_count_increment_1d <= 0) {
        return null
      }
      const res =
        ((this.summary.payment_order_count_increment_today -
          this.statistics_yesterday.payment_order_count_increment_1d) /
          this.statistics_yesterday.payment_order_count_increment_1d) *
        100
      return res
    },
    // 新增订单数量月环比
    paymentOrderCountIncrementMonthlyGrowth() {
      if (this.statistics_last_month.payment_order_count_increment <= 0) {
        return null
      }
      const res =
        ((this.summary.payment_order_count_increment_this_month -
          this.statistics_last_month.payment_order_count_increment) /
          this.statistics_last_month.payment_order_count_increment) *
        100
      return res
    },
  },
  methods: {
    handleGetData() {
      api.summary.index({}).then((data) => {
        // console.log(data)
        if (data.summary) {
          this.summary = data.summary
        }
        if (data.statistics_yesterday) {
          this.statistics_yesterday = data.statistics_yesterday
        }
        if (data.statistics_last_month) {
          this.statistics_last_month = data.statistics_last_month
        }
      })
    },
    handleResize() {
      // if (this.orderChart) this.orderChart.resize()
    },

    handleGetOnlineUser() {
      api.summary.onlineUser({}).then((data) => {
        this.onlineUserTotal = data.online_user_total
        this.maxOnlineUserTotal = data.max_online_user_total
      })
    },
  },
  mounted() {
    this.handleGetData()
    this.handleGetOnlineUser()

    // this.onlineUserTimer = setInterval(() => {
    //   this.handleGetOnlineUser()
    // }, 5000)
  },
  beforeDestroy() {
    if (this.orderChart) {
      this.orderChart.dispose()
      this.orderChart = null
    }
    if (this.onlineUserTimer) {
      clearInterval(this.onlineUserTimer)
    }
  },
}
</script>
<style scoped lang="scss">
.app-info {
  height: 87px;
  .ivu-col {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
}
</style>
