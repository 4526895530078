import request from './request'

export default {
  daily: {
    list(data) {
      return request.post('/StatisticsDaily/list', data)
    },
  },
  monthly: {
    list(data) {
      return request.post('/StatisticsMonthly/list', data)
    },
  },
}
